import { Routes } from '@angular/router';
import { RmaNew } from './rmaAdd/rmaAdd.component';
import { RmaAddProduct } from './rmaAdd/rmaAddComponent.component';
import { BackofficermahistoryComponent } from './backofficermahistory/backofficermahistory.component';
import { BackofficeusermanagementComponent } from './backofficeusermanagement/backofficeusermanagement.component';
import { RmahistoryclientComponent } from './rmahistoryclient/rmahistoryclient.component';
import { RmaAddComponent } from './rmaAdd/rmaAddComponentComp'
import { NewrmaComponent } from './newrma/newrma.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { AdminService } from './services/admin.service';
import { UserService } from './services/user.service';
import { AdminregisterComponent } from './adminregister/adminregister.component';


declare var jQuery: any;

export const SECURE_ROUTES: Routes = [
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: 'rmaAddNew/:clientId/:rmaId', component: RmaNew, canActivate : [UserService] },
  { path: 'rmaAdd/:clientId/:componentId/:rmaId', component: RmaAddProduct, canActivate : [UserService] },
  { path: 'rmaAddC/:clientId/:componentId/:rmaId', component: RmaAddComponent, canActivate : [UserService] },
  { path: 'rmaAdd', component: RmaAddProduct, canActivate : [UserService] },
  { path: 'management', component: BackofficeusermanagementComponent, canActivate : [AdminService]  },
  { path: 'rmahistory/:clientId', component: BackofficermahistoryComponent, canActivate : [AdminService]  },
  { path: 'historyclient/:clientId', component: RmahistoryclientComponent, canActivate : [UserService]  },
  { path: 'newrma/:id', component: NewrmaComponent, canActivate: [AdminService] },
  { path: 'log', component: ActivitylogComponent, canActivate: [AdminService] },
  { path: 'admin/register', component: AdminregisterComponent },



];
