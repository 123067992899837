
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, NgZone, ViewChild } from '@angular/core';
import { UsermanagementService } from './usermanagement.service';
declare var jQuery: any;
import { GetUsers_Result, editoradd } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { NgSelectComponent } from '@ng-select/ng-select';
declare var $: any;

@Component({
  selector: 'app-backofficeusermanagement',
  templateUrl: './backofficeusermanagement.component.html',
  styleUrls: ['./backofficeusermanagement.component.css']
})
export class BackofficeusermanagementComponent implements OnInit {
  userForm: FormGroup;
  userData: GetUsers_Result;
  updateData: editoradd;
  companyData = [];
  isEdit: boolean = false;
  @ViewChild(NgSelectComponent, { static: false })
  ngSelect: NgSelectComponent;

  constructor(private activatedRoute: ActivatedRoute, private rmahistory: UsermanagementService, protected router: Router, private formBuilder: FormBuilder, public ngzone: NgZone) {
    this.updateData = new editoradd();

  }
  ngAfterViewInit() {
    this.rmahistory.GetSerialsByPartialSerial_Result().subscribe((el: any) => {
      console.log(el);
      if (el) {
        this.userData = el.Value;
      }
    }, err => {
      console.log(err)
    })
    this.rmahistory.getUsercompany().subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.companyData = res.Value;
      }
    }, err => {
      console.log(err);
    })


  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
      CustomerId: ['', Validators.required],
      associated: [''],
      Is_Approve: [''],
      phone: ['']
    }
      //   , {
      //     validator: MustMatch('password', 'confirmPassword')
      // }
    );




  }

  redirectToHistroy() {
    this.router.navigate(['/rmahistory/', 41], { relativeTo: this.activatedRoute });
  }
  update(data) {

    console.log(data);
    this.isEdit = true;

    this.updateData.Id = data.ID;
    this.updateData.Name = data.Name;
    this.updateData.Email = data.Email;
    this.updateData.Password = data.Password;
    this.userData.cpassword = data.Password;
    this.updateData.CustomerId = data.Customer_ID;
    this.updateData.Phone = data.Phone;
     let item = this.ngSelect.itemsList.findItem(data.Customer_ID);
    this.ngSelect.select(item);


    //this.updateData.Customer_ID= data.Customer_ID;
    //this.updateData.approve = data.approve;,   

    console.log(this.updateData)
    return
  }

  onChange(ev) {
    // console.log(ev);
    //  console.log(typeof(this.updateData.CustomerId));
  }


  editOrCreate() {
    let arr = '';
    if (this.userForm.get('name').errors)
      arr += 'Name require <br/>';

    if (this.userForm.get('email').errors)
      arr += 'Email require <br/>';

    if (this.userForm.get('password').errors)
      arr += 'Password require <br/>';

    if (this.userForm.get('cpassword').errors)
      arr += 'Confirm Password require <br/>';

    if (this.userForm.get('associated').value == undefined || !this.userForm.get('associated').value) {

      if (this.userForm.get('CustomerId').errors)
        arr += 'Company name require <br/>';
    }

    if (this.userForm.get('password').value != this.userForm.get('cpassword').value)
      arr += 'password and confirm password does not match';

    if (arr != '') {
      $.MessageBox(arr);
    } else {
      let payload;
      console.log(this.userForm.value);

      if (this.userForm.value.associated) {
        this.userForm.value.CustomerId = 0;
      } else {
        console.log(this.userForm.value.CustomerId);
        //   this.userForm.value.CustomerId = parseInt(this.updateData.CustomerId.toString());
      }
      payload = this.userForm.value;
      payload.Id = this.updateData.Id;
      console.log(payload)
      this.rmahistory.aditOrUpdate(payload).subscribe((res: any) => {
        console.log(res);
        console.log(payload.Id);
        if (this.isEdit) {
          $.MessageBox('User updated successfully!!!');
        }
        else {
          $.MessageBox('User created successfully!!!');
        }
        this.userData = res.Value;
        this.userForm.reset();
        this.isEdit = false;
      }, err => {
        $.MessageBox('There is something wrong please try again!!!');
        console.log(err);
      })
    }
  }


  delete(id) {
    this.rmahistory.deleteUser(id).subscribe((res: any) => {
      console.log(res);
      this.userData = res.Value;

    }, err => {
      console.log(err);
    })
  }



  getFormValidationErrors() {

    Object.keys(this.userForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.userForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {

          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });


  }


  scrollToElement(element: HTMLElement): void {
    console.log(element);

    //   this.userFormRef.nativeElement.scrollIntoView();
    // element.scrollIntoView();
    // console.log(element);
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

    //let el = document.getElementById(element);
    //el.scrollIntoView();
  }

}
