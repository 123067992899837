import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'slimDescription'
})
export class slimDescription implements PipeTransform {

    transform(description: string, args?: any[]): string {

        if (!description) {
            return '-';
        }
        if (args) {
        	let maxLength = +args;
			return description.length > maxLength ? description.slice(0, maxLength).trim() + ".." : description;        	
        }

        return description.length > 40 ? description.slice(0, 40).trim() + ".." : description;
    }


}
