import { Injectable, Inject } from '@angular/core';
import { Jsonp, URLSearchParams, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { getRmahistoryData } from "../model/dbContext";
import { catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
//import { Globalconfig } from './../globalconfig';
import { config } from '../app.global';


@Injectable({
  providedIn: 'root'
})
export class NewrmaserviceService {
  baseUrl: string;
 // baseUrl: string = config.baseUrl;

  //baseUrl: string = "http://192.168.0.104:8003/";
  constructor(protected router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this.baseUrl = baseUrl; }
 // constructor(protected router: Router,private http: HttpClient) {  }


  getrma(id) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get(this.baseUrl + 'api/NewRMA/GetRMA/' + id).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }



  getRmaDetails(RmaId) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get(this.baseUrl + 'api/NewRMA/GetRMADetails/' + RmaId).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }



  getRmaComponets(RmaId) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get(this.baseUrl + 'api/NewRMA/GetRMAComponets/' + RmaId).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }
  GetFileForComponentCount(id) {
   

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    let params = new HttpParams().set('Id', id.toString());
    return this.http.get(this.baseUrl + 'api/NewRMA/GetRMAComponetsFilesCount/', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }
  GetFileForProductCount(id) {


    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    let params = new HttpParams().set('Id', id.toString());
    return this.http.get(this.baseUrl + 'api/NewRMA/GetRMAProductFilesCount/', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }

  GetFileForComponent(data) {
 

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";
    
    let params = new HttpParams().set('componentData', JSON.stringify(data));
    return this.http.get(this.baseUrl + 'api/NewRMA/GetFileForComponent/', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }

  GetFileProduct(data) {
   
    data.ComponentName = "";
    data.ComponentDescription = "";
    data.IssueLevel = "";
    data.Amount = 1;

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    let params = new HttpParams().set('componentData', JSON.stringify(data));
    return this.http.get(this.baseUrl + 'api/NewRMA/GetFileProduct/', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }
  downloadFiles(data) {

  }


  statusUpdate(data) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.post(this.baseUrl + 'api/NewRMA/UpdateStatus', data).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));

  }



  updateDetailChecklist(e) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get(this.baseUrl + 'api/NewRMA/UpdateRMADetailsGrid/' + e.Id + '/' + e.e + '/' + e.detail).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }

  updateComponetnChecklist(value) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get(this.baseUrl + 'api/NewRMA/UpdateRMAComponentGrid/' + value.Id + '/' + value.e + '/' + value.detail).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }


  private handleError(error: Response, funcName: string, freeText: string, clientId?: string, serverName = "0") {

    if (clientId == "")
      clientId = "0";
    var errors = error.text();

    if (error.text().indexOf("Token doesn't exist") > -1) {
      console.log("Token doesn't exist \n redirect to login");
      this.router.navigate(['/login']);//cookie doesn't exist->back to login       
    }

    //create async writes to log
    var a = this.WriteToClientLog(funcName.split("(")[0], freeText, "test", "").subscribe(el => console.log(el),
      err => console.error(err),
      () => console.log("Processing Complete.")
    );

    return Observable.throw(error.text() || 'Server error');
  }
  WriteToClientLog(exp: string, text: string, tokenId: string, logFileName: string) {
    const params = new HttpParams().set('tokenId', "test").set('text', text).set('exp', exp)
      .set('logFileName', logFileName);
    return this.http.get<getRmahistoryData[]>(this.baseUrl + 'api/SilicomData/writeLog', { params });

  }



  updateComponentdisapprove(value){
    var stack = new Error().stack,
    funcNameLog = stack.split('\n')[1].trim();
  var freeTextLog = "testApi service";

  return this.http.post(this.baseUrl + 'api/NewRMA/UpdateRmaComponent', value).pipe(
    catchError(err => {
      var stack = new Error().stack,
        funcNameLog = stack.split('\n')[1].trim();
      var freeTextLog = "testApi service";
      return of(null);
    }));
  }

    
    updateproductdisapprove(value){
      var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.post(this.baseUrl + 'api/NewRMA/UpdateRmaDetails', value).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }


}
