import { Injectable, Inject } from '@angular/core';
import { Jsonp, URLSearchParams, Response, ResponseContentType } from '@angular/http';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetSerialsByPartialSerial_Result, GetSerialDetails_Result, RmaDetails, rmaDataIncludeFiles, Rma, ReduceComponentDetails, RmaComponent, rmaDataCIncludeFiles } from "../model/dbContext";
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { config } from '../app.global';
import { RmaComponentAdd } from "../model/dbContext";


@Injectable()
export class RmaAddService {
  baseUrl: string;
  //baseUrl: string = config.baseUrl;
  constructor(protected router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this.baseUrl = baseUrl; }
  //constructor(protected router: Router, private http: HttpClient) { }

  GetSerialsByPartialSerial_Result(partSerial: string) {

    const params = new HttpParams().set('tokenId', "test").set('partSerial', partSerial);

    //let params = new URLSearchParams();
    //params.set('tokenId', this._cookieService.get("token") ? this._cookieService.get("token") : "");
    //params.set('days', timeFrame);

    //logs verbs

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get<GetSerialsByPartialSerial_Result[]>(this.baseUrl + 'api/SilicomData/GetSerialsByPartialSerial_Result', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }
  DeleteFile(fileName: string, clientId: string, componentNumber: string, rmaId: string) {

    const params = new HttpParams().set('tokenId', "test").set('fileName', fileName)
      .set('clientId', clientId).set('componentNumber', componentNumber).set('rmaId', rmaId);

    //let params = new URLSearchParams();
    //params.set('tokenId', this._cookieService.get("token") ? this._cookieService.get("token") : "");
    //params.set('days', timeFrame);

    //logs verbs

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.delete(this.baseUrl + 'api/SilicomData/deleteFile', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }
  AddOrUpdateComponent(componentNumber: RmaDetails, clientId: string, isEdit: boolean, rmaId: string) {



    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    //const params = new HttpParams().set('tokenId', "test").set('clientId', clientId).set('rmaId', rmaId)
    //  .set('isEdit', isEdit.toString()).set('componentNumber', JSON.stringify(componentNumber));
    //const options = {
    //  headers: headers,
    //  params: params
    //};

    var rmaD = new RmaComponentAdd();
    rmaD.clientId = clientId;
    rmaD.rmaId = rmaId;
    rmaD.componentNumber = componentNumber;
    rmaD.tokenId = "test";
    rmaD.isEdit = isEdit;
    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.post<string>(this.baseUrl + 'api/SilicomData/AddOrUpdateComponent', rmaD).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }
  AddOrUpdateComponentComp(componentNumber: RmaComponent, clientId: string, isEdit: boolean, rmaId: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const params = new HttpParams().set('tokenId', "test").set('clientId', clientId).set('rmaId', rmaId)
      .set('isEdit', isEdit.toString()).set('componentNumber', JSON.stringify(componentNumber));
    const options = {
      headers: headers,
      params: params
    };

    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get<string>(this.baseUrl + 'api/SilicomData/AddOrUpdateComponentComp', options).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  getComponentData(clientId: string, componentNumber: string, IsC: boolean = false) {

    const params = new HttpParams().set('tokenId', "test").set('clientId', clientId).set('componentNumber', componentNumber);
    const options = {
      params: params
    };

    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";
    if (!IsC) {
      return this.http.get<rmaDataIncludeFiles>(this.baseUrl + 'api/SilicomData/getComponentData', options).pipe(
        catchError(err => {
          var stack = new Error().stack,
            funcNameLog = stack.split('\n')[1].trim();
          var freeTextLog = "testApi service";
          return of(null);
        }));
    }
    else {
      return this.http.get<rmaDataCIncludeFiles>(this.baseUrl + 'api/SilicomData/getComponentCompData', options).pipe(
        catchError(err => {
          var stack = new Error().stack,
            funcNameLog = stack.split('\n')[1].trim();
          var freeTextLog = "testApi service";
          return of(null);
        }));
    }
  }
  getComponentList(clientId: string, rmaId: string) {

    const params = new HttpParams().set('tokenId', "test").set('clientId', clientId).set('rmaId', rmaId);
    const options = {
      params: params
    };

    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get<ReduceComponentDetails>(this.baseUrl + 'api/SilicomData/getComponentList', options).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  getRmaData(clientId: string, rmaId: string) {

    const params = new HttpParams().set('tokenId', "test").set('clientId', clientId).set('rmaId', rmaId);
    const options = {
      params: params
    };

    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";


    return this.http.get<any>(this.baseUrl + 'api/SilicomData/getRmaData', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  getUser(email: string, pass: string) {
    console.log(this.baseUrl)
    console.log(email, pass);
    const params = new HttpParams().set('email', email).set('password', pass);
    const options = {
      params: params
    };
    //logs verbs
    // var stack = new Error().stack,
    //   funcNameLog = stack.split('\n')[1].trim();
    // var freeTextLog = "testApi service";    
    return this.http.get<any>(this.baseUrl + 'api/SilicomData/getUser', { params })
    // .pipe(
    //   catchError(err => {
    //     var stack = new Error().stack,
    //       funcNameLog = stack.split('\n')[1].trim();
    //     var freeTextLog = "testApi service";
    //     return of(null);
    //   }));
  }

  registeruser(registerdata) {
    console.log(registerdata);
    // const params = new HttpParams().set('email', email).set('password', pass);
    // const options = {
    //   params: params
    // };     
    return this.http.post<any>(this.baseUrl + 'api/SilicomData/getUser', { registerdata })
  }



  deleteC(id: string, isC: string) {

    const params = new HttpParams().set('id', id).set("isC", isC.toString());
    const options = {
      params: params
    };

    //logs verbs
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";


    return this.http.get<any>(this.baseUrl + 'api/SilicomData/deleteC', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  GetSerialDetails_Result(serialNumber: string) {

    const params = new HttpParams().set('tokenId', "test").set('serialNumber', serialNumber);

    //let params = new URLSearchParams();
    //params.set('tokenId', this._cookieService.get("token") ? this._cookieService.get("token") : "");
    //params.set('days', timeFrame);

    //logs verbs

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get<GetSerialDetails_Result[]>(this.baseUrl + 'api/SilicomData/GetSerialDetails_Result', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  GetSerialDetails_ResultForComponent(serialNumber: string) {

    const params = new HttpParams().set('tokenId', "test").set('serialNumber', serialNumber);

    //let params = new URLSearchParams();
    //params.set('tokenId', this._cookieService.get("token") ? this._cookieService.get("token") : "");
    //params.set('days', timeFrame);

    //logs verbs

    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.get<GetSerialDetails_Result[]>(this.baseUrl + 'api/SilicomData/GetSerialDetails_ResultForComponent', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }

  // file from event.target.files[0]
  uploadFile(files: FileList, clientId: string, componentNumber: string, rmaId: string): Observable<HttpEvent<any>> {
    let formData = new FormData();
    Array.from(files).forEach(file => { formData.append(file.name, file) });
    let params = new HttpParams().set('tokenId', "test")
      .set('clientId', clientId).set('componentNumber', componentNumber).set('rmaId', rmaId);

    const options = {
      params: params
    };

    const req = new HttpRequest('POST', this.baseUrl + 'api/SilicomData/UploadFile', formData, options);
    return this.http.request(req);
  }

  // file from event.target.files[0]
  addOrUpdareRma(rmaDetails1: Rma, clientId: string, rmaId) {

    let params = new HttpParams().set('tokenId', "test")
      .set('clientId', clientId).set('rmaId', rmaId).set('rmaDetails1', JSON.stringify(rmaDetails1)).set('isEdit', rmaId == "-1" ? "false" : "true");


    return this.http.get<string>(this.baseUrl + 'api/SilicomData/addOrUpdareRma', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  updateTrackingNumber(rmaDetails1: Rma, clientId: string, rmaId) {

    let params = new HttpParams().set('tokenId', "test")
      .set('clientId', clientId).set('rmaId', rmaId).set('rmaDetails1', JSON.stringify(rmaDetails1)).set('isEdit', rmaId == "-1" ? "false" : "true");


    return this.http.get<string>(this.baseUrl + 'api/SilicomData/updateTrackingNumber', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }
  // file from event.target.files[0]
  updateSatus(clientId: string, rmaId: string) {

    let params = new HttpParams().set('tokenId', "test")
      .set('clientId', clientId).set('rmaId', rmaId);


    return this.http.get<string>(this.baseUrl + 'api/SilicomData/updateSatus', { params }).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));
  }

  DownloadFile(filePath: string, fileType: string): Observable<any> {
    let fileExtension = fileType;
    let input = filePath;
    return this.http.post(this.baseUrl + 'api/SilicomData/DownloadFile?fileName=' + input, '', { responseType: 'blob' })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: fileExtension });
        }));
  }

  private handleError(error: Response, funcName: string, freeText: string, clientId?: string, serverName = "0") {

    if (clientId == "")
      clientId = "0";
    var errors = error.text();

    if (error.text().indexOf("Token doesn't exist") > -1) {
      console.log("Token doesn't exist \n redirect to login");
      this.router.navigate(['/login']);//cookie doesn't exist->back to login       
    }

    //create async writes to log
    var a = this.WriteToClientLog(funcName.split("(")[0], freeText, "test", "").subscribe(el => console.log(el),
      err => console.error(err),
      () => console.log("Processing Complete.")
    );

    return Observable.throw(error.text() || 'Server error');
  }
  WriteToClientLog(exp: string, text: string, tokenId: string, logFileName: string) {
    const params = new HttpParams().set('tokenId', "test").set('text', text).set('exp', exp)
      .set('logFileName', logFileName);
    return this.http.get<GetSerialsByPartialSerial_Result[]>(this.baseUrl + 'api/SilicomData/writeLog', { params });

  }



  public upload(file) {

    return this.http.post(this.baseUrl + 'api/NewRMA/UploadRMAExcel', file)
    // let uploadURL = `${this.SERVER_URL}/auth/${userId}/avatar`;

    // return this.httpClient.post<any>(uploadURL, data, {
    //   reportProgress: true,
    //   observe: 'events'
    // }).pipe(map((event) => {

    //   switch (event.type) {

    //     case HttpEventType.UploadProgress:
    //       const progress = Math.round(100 * event.loaded / event.total);
    //       return { status: 'progress', message: progress };

    //     case HttpEventType.Response:
    //       return event.body;
    //     default:
    //       return `Unhandled event: ${event.type}`;
    //   }
    // })
    // );
  }


}
