import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { Router, CanActivate } from '@angular/router';
import { Subscription } from 'rxjs';
import { RmaAddService } from '../rmaAdd/rmaAdd.service';
declare var $: any;
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login.service';



@Component({
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.Emulated,

})
export class LoginComponent {
  constructor(private addService: RmaAddService, private cookieService: CookieService, private activatedRoute: ActivatedRoute,
    protected router: Router, public loginSrc: LoginService) { }

  ngOnInit() {
  }

  public pageTitle: string = 'Welcome';
  public cookieVal: string = "";
  token: string;
  userName = '';
  password = '';
  reportId: string;
  client: any;

  public subscription: Subscription;
  onKey(event: any) { // without type info
    let tar = event.target;
    if (tar.value)
      $(tar).addClass("has-val");
    else
      $(tar).removeClass("has-val");

  }

  // async validateLogin() {
  //   console.log('hello')
  //   let id = '40'

  //   if (($("#txtEmail").val() == "experda") && ($("#txtPass").val() == "experda"))
  //     this.router.navigate(['/historyclient/', id], { relativeTo: this.activatedRoute });
  //   else if (($("#txtEmail").val() == "admin") && ($("#txtPass").val() == "683dw63P!")) {
  //     this.cookieService.set("ClientId", "a");
  //     this.router.navigate(['/management/'], { relativeTo: this.activatedRoute });
  //   }
  //   else
  //     this.checkFromDb();
  //   let data = { 'Email': $("#txtEmail").val(), 'Password': $("#txtPass").val() };
  //   this.loginSrc.loginuser(data).subscribe(async (res: any) => {
  //     console.log(res);
  //     console.log(res.Value)
  //     if (res.Value) {
  //       await this.loginSrc.setTokenData(res.value);

  //     }
  //   }, err => {
  //     console.log(err)
  //   })

  // }
  // async  checkFromDb() {

    async validateLogin(){
    this.addService.getUser($("#txtEmail").val(), $("#txtPass").val()).subscribe(async (el) => {
      console.log(el);
      this.client = el;
      this.cookieService.set("Name", el.Name);
      this.cookieService.set("Phone", el.Phone);
      this.cookieService.set("Email", el.Email);
      this.cookieService.set("CompanyName", el.CompanyName);
      this.cookieService.set("ClientId", this.client.Id);
      this.cookieService.set("Address", this.client.Address ? this.client.Address : "");

      if (this.client.Id) {
        if (!this.client.IsApprove) {
          $.MessageBox("Account is waiting for activation");
          return false;
        }
        
        let data = { ...el, UserLevel: 'rmauser' }
        await this.loginSrc.setUserData(data);
        await this.loginSrc.setTokenData(data.accessToken);
        this.loginSrc.setUserData(data);
        this.loginSrc.updateSignIn();
        this.router.navigate(['/historyclient/', this.client.Id], { relativeTo: this.activatedRoute });
      }
      else {
        $.MessageBox("Login Failed");
      }

    },
      err => console.error(err),
      () => {
      }
    );
  }



}
