import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit } from '@angular/core';
import { RmaAddService } from './rmaAdd.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;
import Utils from '../helper/helpers'
import { saveAs } from 'file-saver';
import { config } from '../app.global';
import { GetSerialsByPartialSerial_Result, GetSerialDetails_Result, RmaDetails, rmaDataIncludeFiles } from "../model/dbContext";
 
declare var $: any;
@Component({
  templateUrl: './rmaAddComponent.component.html'
})


export class RmaAddProduct implements OnInit, OnDestroy, AfterViewInit {
  baseUrl: string = config.baseUrl;
  getSerail: GetSerialsByPartialSerial_Result;
  serialDetails: GetSerialDetails_Result[] = [];
  FileListNames: any[] = [];
  FileListNamesTmp: any[] = [];
  isEdit: boolean = false;
  componentId = "-1";
  clientId = "0";
  component: RmaDetails = new RmaDetails();
  componentDetails: rmaDataIncludeFiles;
  rmaId: string;
  chooseFromList: boolean = false;
  isEmpty: boolean = false;
  isEmpty2: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private addService: RmaAddService, protected router: Router, private formBuilder: FormBuilder) {}
  ngAfterViewInit() {
 
    if ($(".auto").length > 0) {
      var self = this;
      var options = {

        url:  (phrase)=> {
          return this.baseUrl+"api/SilicomData/GetSerialsByPartialSerial_Result";
        },

        getValue: function (element) {
          return element.Part_Serial;

        },
        list: {
          onClickEvent: function () {
            let serial = $("#serial-ajax-post").val();
            self.addService.GetSerialDetails_Result(serial).subscribe(el => {
              self.serialDetails = el;
              $("#txtDelivery").text(Utils.typeSafeM(self.serialDetails[0].Delivery_Date.toString()));
              $("#txtInvoice").text(Utils.typeSafeM(self.serialDetails[0].Invoice_ID));
              $("#txtHTS").text(Utils.typeSafeM(self.serialDetails[0].HTS));
              $("#txtPartName").text(Utils.typeSafeM(self.serialDetails[0].Part_Name));

            var interval=  setInterval(() => {
              if ($(".loadingoverlay").length > 0) {

                $("#imgLoad").css("display", "none");
                $(".overlay").LoadingOverlay("hide");
                $(".overlay").LoadingOverlay("hide");
                $(".overlay").LoadingOverlay("hide");
                $(".overlay").LoadingOverlay("hide");
                $(".overlay").LoadingOverlay("hide");
                $(".overlay").LoadingOverlay("hide");
              }
              else {
                clearInterval(interval);
              }
              $(".overlay").LoadingOverlay("hide");

              }, 1000);

            
              if (self.serialDetails[0].Is_In_Warranty)
                $("#chkinWarrenty").prop("checked", true);

              self.chooseFromList = true;

              console.log("el=", self.serialDetails[0].Delivery_Date);
              $("#serial").html($("#serial-ajax-post").val());

            },
              err => console.error(err),
              () => {
              }
            );
          },
          onHideListEvent: function () {
      
          },
          onLoadEvent: function () {
            self.chooseFromList = false;
            var timerID = setInterval(() => {
              if (self.isEmpty) {
                clearInterval(timerID);
                $("#imgLoad").css("display", "none");
                $(".overlay").LoadingOverlay("hide");
              }
            }, 1000);

           
            
          },
          onShowListEvent: function () {
            console.log("Finish loading");
            $("#imgLoad").css("display", "none");
            $(".overlay").LoadingOverlay("hide");
          }
        },

        ajaxSettings: {
          dataType: "json",
          method: "GET",
          data: {
            tokenId: "json",
            UserId:self.clientId
          }, success: function (data) {
            if (data.length == 0) {
              $.MessageBox("No Results");
              self.isEmpty = true;
              $("#imgLoad").css("display", "none");
              $(".overlay").LoadingOverlay("hide");
            }
          }
        },

        preparePostData: function (data) {
          data.partSerial = $("#serial-ajax-post").val();
          return data;
        },
        requestDelay: 400
      };

      (<any>$("#serial-ajax-post")).easyAutocomplete(options);

      var options2 = {

        url: function (phrase) {
          return "api/SilicomData/GetSerialsByPartNo";
        },

        getValue: function (element) {
          return element.Part_Serial;
        },
        list: {
          onClickEvent: function () {
            let serial = $("#serial-ajax-post2").val();
            self.addService.GetSerialDetails_Result(serial).subscribe(el => {
              self.chooseFromList = true;
              self.serialDetails = el;
              $("#txtDelivery").text(Utils.typeSafeM(self.serialDetails[0].Delivery_Date.toString()));
              $("#txtInvoice").text(Utils.typeSafeM(self.serialDetails[0].Invoice_ID));
              $("#txtHTS").text(Utils.typeSafeM(self.serialDetails[0].HTS));
              $("#txtPartName").text(Utils.typeSafeM(self.serialDetails[0].Part_Name));
            

              $("#imgLoad").css("display", "none");
              $(".overlay").LoadingOverlay("hide");

              if (self.serialDetails[0].Is_In_Warranty)
                $("#chkinWarrenty").prop("checked", true);
              console.log("el=", self.serialDetails[0].Delivery_Date);
              $("#serial").html($("#serial-ajax-post2").val());
              $("#imgLoad").css("display", "none");
              $(".overlay").LoadingOverlay("hide");
              $(".loadingoverlay").css("display", "none");


            },
              err => console.error(err),
              () => {
                $("#imgLoad").css("display", "none");
                $(".overlay").LoadingOverlay("hide");
              }
            );
          },
          onLoadEvent: function () {
            console.log("loading");
            self.chooseFromList = false;
          
            $("#imgLoad2").css("display", "inline");
            var timerID = setInterval(() => {
              if (self.isEmpty2) {
                clearInterval(timerID);
                $("#imgLoad2").css("display", "none");
              }
            }, 1000);
          },
          onShowListEvent: function () {
            console.log("Finish loading");
            $("#imgLoad2").css("display", "none");
          }
        },

        ajaxSettings: {
          dataType: "json",
          method: "GET",
          data: {
            tokenId: "json",
             UserId: self.clientId
          }, success: function (data) {
            if (data.length == 0) {
              self.isEmpty2 = true;
              $("#imgLoad2").css("display", "none");
              $.MessageBox("No Results");
            }
          }
        },

        preparePostData: function (data) {
          data.partSerial = $("#serial-ajax-post2").val();
          return data;
        },
        requestDelay: 400
      };

      (<any>$("#serial-ajax-post2")).easyAutocomplete(options2);
    }
  }
  onKey(event,num) {
    console.log('hello')
    console.log("keyup");
  
    if (num == 2) {
      if ($("#serial-ajax-post2").val() == "") {
        $("#imgLoad2").css("display", "none");
      }
      else
      $("#imgLoad2").css("display", "inline");
    }
    else {
      if ($("#serial-ajax-post").val() == "") {
        $("#imgLoad").css("display", "none");
        $(".overlay").LoadingOverlay("hide");
      }
      else {
        $("#imgLoad").css("display", "inline");
         $(".overlay").LoadingOverlay("show");
        
      }
    }

    
  }
  focusOutFunction(num) {
    console.log(num)
    if (num == 1) {
      $("#imgLoad").css("display", "none");
      $(".overlay").LoadingOverlay("hide");
    }
    else {
      $("#imgLoad2").css("display", "none");
    }
  }


  uploadFile(files: FileList,clientId:string,componentId:string,rmaId:string) {
    if (files.length == 0) {
      console.log("No file selected!");
      this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
      return;

    }
    let file: FileList = files;

    this.addService.uploadFile(file, clientId, componentId,rmaId)
      .subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            console.log(`File is ${percentDone}% loaded.`);
          } else if (event instanceof HttpResponse) {
            console.log('File is completely loaded!');
          }
        },
        (err) => {
          console.log("Upload Error:", err);
        }, () => {
          console.log("Upload done");
          this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
        }
      )
  }
  redirectBack() {
    this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
  }
  saveDrafts() {
    $("#imgLoad3").css("display", "inline");
    $("#send").css("display", "none");
    if (($("#serial-ajax-post").val()=="" && $("#serial").html()=="")) {
      $.MessageBox("Serial or Part Id Are Missing");
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      return;
    }
    if (!this.chooseFromList) {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Please Choose Serial or Part Id From List");
      return;
    }
    if ($("#txtF").val() == "") {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Please Fill Fallure Description");
      return;
 
    }
    if ($("#chkDOA").val() == "") {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Please choose DOA");
      return;
    }
    if ($("#chkHard").val() != "Intermittant" && $("#chkHard").val() != "Hard" ) {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Please choose Issue Level");
      return;
    }

    var files2 = (<HTMLInputElement>document.getElementById('file-2')).files;

    var totalSize = 0;
    for (var x in files2) {
      var filesize = ((files2[x].size / 1024) / 1024).toFixed(4); // MB
      if (!isNaN(parseInt(filesize)))
      totalSize += parseInt(filesize);
      if (parseInt(filesize) > 4) {
        $("#imgLoad3").css("display", "none");
        $("#send").css("display", "inline");
        $.MessageBox("File size to big");
      
        return;
      }
    }
    if (totalSize > 10) {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Files size to big");
      return;
    }

    this.component.IsInWarranty = $("#chkinWarrenty").is(":checked");
    this.component.IsDoa = $("#chkDOA").val() == 'true' ? true : false;
    this.component.Hts = $("#txtHTS").text();
    this.component.IsMadeInIsrael = $("#chkIsrael").is(":checked");
    this.component.IsSentWithPowerpack = $("#chkPP").is(":checked");
    this.component.PartId = parseInt($("#txtPartName").attr("data-id"));
    this.component.Serial = $("#serial").text();
    this.component.FixType = $("#chkFix").val();
    this.component.IssueLevel = $("#chkHard").val();
    this.component.Description = $("#txtF").val();
    this.component.BoardFailed = $("#txtBf").val();
    this.component.SlotNumber = parseInt($("#txtSn").val());
    this.component.InvoiceId = $("#txtInvoice").text();
    this.component.Part_Name = $("#txtPartName").text();
    this.component.Delivery_Date = $("#txtDelivery").text();
    this.component.FailedCard = $("#txtTestType").val();
   
  //  window["product"].push({ "comp": this.component, "files": files });
   // console.log(window["product"]);
    if (this.isEdit) {//new component
      this.component.Id = parseInt(this.componentId);
    //  this.component.RmaId = "R0800001";
    }

    this.addService.AddOrUpdateComponent(this.component, this.clientId, this.isEdit, this.rmaId).subscribe(el => {
      this.componentId = el;
      var files = (<HTMLInputElement>document.getElementById('file-2')).files;

     
      this.uploadFile(files, this.clientId, this.componentId,this.rmaId);
    },
      err => console.error(err),
      () => {

      }
    );
  }
  deleteFile() {
    this.FileListNames.splice(this.FileListNames.indexOf($(target).parent("em").attr("data-name"), 1));
    var target = event.target || event.srcElement || event.currentTarget;
    if (!this.isEdit) {//new component
   
      console.log($(target).parent("em").attr("data-name"));

    }
    else {
      this.addService.DeleteFile($(target).parent("em").attr("data-name"),this.clientId,this.componentId,this.rmaId).subscribe(el => {
        console.log(el);
      },
        err => console.error(err),
        () => {
        }
      );
    }
 
  }
  ngOnInit() {
    console.log("hhh");//
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.componentId = this.activatedRoute.snapshot.paramMap.get('componentId');
    console.log("this.componentId",this.componentId);
    this.rmaId = this.activatedRoute.snapshot.paramMap.get('rmaId');
    if (this.componentId == "-1")
      this.isEdit = false;
    else//update
    {
      this.fillControls();
      this.chooseFromList = true;
    }

    //this.component = this.formBuilder.group({
    //  name: ['', Validators.required],
    //  email: ['', Validators.required],
    //  password: ['', Validators.required],
    //  cpassword: ['', Validators.required],
    //  CustomerId: ['', Validators.required],
    //  associated: ['']
    //}); 
  }
  fillControls() {
    this.isEdit = true;
    //todo
    //pull data //pull files
    this.addService.getComponentData(this.clientId,this.componentId, ).subscribe(el => {
      this.componentDetails = el;
      this.FileListNames = this.componentDetails.Files ? this.componentDetails.Files:[];
      this.component = this.componentDetails.RmaDetails;
   
      //typesafe
      $("#chkinWarrenty").attr("checked", this.component.IsInWarranty);
      //$("#chkDOA").attr("checked", this.component.IsDoa);
      $("#chkDOA").val(this.component.IsDoa.toString());
      $("#txtHTS").text(Utils.typeSafeM(this.component.Hts));
      $("#chkIsrael").attr("checked", this.component.IsMadeInIsrael);
      $("#chkPP").attr("checked", this.component.IsSentWithPowerpack);
      $("#txtPartName").attr("data-id", Utils.typeSafeNull(this.component.PartId.toString()));
      $("#txtPartName").html(Utils.typeSafeNull(this.component.Part_Name));
      $("#serial").text(Utils.typeSafeM(this.component.Serial));
      $("#chkFix").val(this.component.FixType);
      $("#chkHard").val(this.component.IssueLevel);
      $("#txtF").val(Utils.typeSafeNull(this.component.Description));
      $("#txtBf").val(Utils.typeSafeNull(this.component.BoardFailed));
      $("#txtSn").val(this.component.SlotNumber ? this.component.SlotNumber.toString() :0);
      $("#txtInvoice").text(Utils.typeSafeM(this.component.InvoiceId));
      $("#txtDelivery").text(Utils.typeSafeM(this.component.Delivery_Date));
      $("#txtTestType").val(Utils.typeSafeNull(this.component.FailedCard));//
      
    },
      err => console.error(err),
      () => {
      }
    );
    
  
  }

  uploadChange() {

    var files = (<HTMLInputElement>document.getElementById('file-2')).files;
    let formData = new FormData();

    if (!this.isEdit) {
      this.FileListNames = [];
    }
    else {
      for (var f of this.FileListNamesTmp) {
        var index = this.FileListNames.indexOf(f);
        if (index != -1) {
          this.FileListNames.splice(index, 1);
        }

      }
      this.FileListNamesTmp = [];
    }

    Array.from(files).forEach(file => { this.FileListNames.push(file.name); this.FileListNamesTmp.push(file.name) });

  }

  DownLoadFiles(data:string) {
    let fileName = data;
    //file type extension
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "txt") {
      fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == "doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == "png") {
      fileType = "image/png";
    }
    if (checkFileType == "jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "gif") {
      fileType = "image/gif";
    }
    if (checkFileType == "csv") {
      fileType = "text/csv";
    }
    this.addService.DownloadFile(fileName, fileType)
      .subscribe(
        success => {
          saveAs(success, fileName);
        },
        err => {
          alert("Server error while downloading file.");
        }
      );
  }

  


  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    // this.subscription.unsubscribe();

  }
}
