

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UsermanagementService } from '../backofficeusermanagement/usermanagement.service';
import { LoginService } from '../login/login.service';
import { AdminUser } from "../model/dbContext";

declare var $: any;


@Component({
  selector: 'app-adminregister',
  templateUrl: './adminregister.component.html',
  styleUrls: ['./adminregister.component.css']
})
export class AdminregisterComponent implements OnInit {

  public registerForm: FormGroup;
  registerdata: any = {};
  adminDataList : AdminUser []=[];

  constructor(public loginsrc: LoginService, public router: Router, public formBuilder: FormBuilder, private rmahistory: UsermanagementService) {
    this.registerForm = formBuilder.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      Password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Level: ['', Validators.required]
    });


    this.registerdata.Level = 1
  }

  ngOnInit() {
    this.loginsrc.getAdminUser().subscribe((res: any) => {
      if (res) {
        console.log(res.Value);
        this.adminDataList = res.Value;
      }
    }, err => {
      console.log(err)
    })
  }

  ngAfterViewInit() {
  
   


  }

  delete(id) {
    this.loginsrc.deleteAdminUser(id).subscribe((res: any) => {
      console.log(res);
      this.adminDataList = res.Value;

    }, err => {
      console.log(err);
    })
  }

  onKey(event: any) { // without type info
    let tar = event.target;
    if (tar.value)
      $(tar).addClass("has-val");
    else
      $(tar).removeClass("has-val");

  }

  register() {

    if (this.registerForm.invalid) {
      console.log(this.registerForm.value)
      $.MessageBox("There are some errors on the form");
      return false;
    }
    if ((this.registerdata.Password != this.registerdata.confirmpassword)) {
      $.MessageBox("Password and confirm password don't match");
      return false;
    }
    //$(".login100-form-btn").hide();
    // $("#imgLoad").show();
    this.registerdata.Level = parseInt(this.registerdata.Level)
    console.log(this.registerdata)
  
    //this.registerdata.Level(parseInt(this.registerdata.Level));
    this.loginsrc.resiterAdmin(this.registerdata).subscribe((res: any) => {
      console.log(res);
      if (res.Value == 'success') {
        $.MessageBox("Account created successfully");
        this.ngOnInit();
      } else {
        $.MessageBox("Please choose another Email");

      }
      $(".login100-form-btn").show();
      $("#imgLoad").hide();
      //$.MessageBox(err.error.Value);
    }, err => {
      $.MessageBox("Something went wrong");
      $(".login100-form-btn").show();
      $("#imgLoad").hide();
      console.log(err);
      })
  
    // this.rmahistory.aditOrUpdate(this.registerdata).subscribe((res: any) => {
    //   console.log(res);
    //   $.MessageBox("Your account is created, please wait for administrator to approve your account");

    //   setTimeout(() => this.redirect(), 5000);
    // }, err => {
    //   console.log(err);
    //   $(".login100-form-btn").hide();
    //   $("#imgLoad").show();
    //   $.MessageBox(err.error.Value);
    // })
  }
  redirect() {
    this.router.navigate(['/login']);
  }



}
