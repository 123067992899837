//import { Component, OnInit } from '@angular/core';
//import { Subscription } from 'rxjs';
//import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
//import { RmahistoryService } from './rmahistory.service';
declare var jQuery: any;
//import { getRmahistoryData, GetRMAHistorySilicom_Result } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { ActivitylogService } from './activitylog.service';
//import { ModalDirective } from 'ngx-bootstrap/modal/public_api';
declare var $: any;

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css']
})
export class ActivitylogComponent implements OnInit {
  logData: any = [];
  activity: any = {};
  submitted = false;
  //@ViewChild('smallModal', { read: ModalDirective, static: false }) public smModal: ModalDirective;
  logForm: FormGroup;
  constructor(public formBuilder: FormBuilder, public activitySrc: ActivitylogService) {
    this.logForm = this.formBuilder.group({
      // Name: ['', Validators.required],
      // Email: ['', [Validators.required, Validators.email]],
      Name: [''],
      Email: [''],
      CreateUser: [true],
      ApproveRMA: [false],
      Login: [false],
      Logout: [false],
      CreateRMA: [false],
      UpdateRMA: [false],
      ApproveUser: [false],
      SendTracking: [false],


    });
  }

  ngOnInit() {
  }
  get f() { return this.logForm.controls; }

  serach() {
    this.submitted = true;
    if (this.logForm.invalid) {
      return;
    }
    this.activitySrc.getActivityLog(this.logForm.value).subscribe(el => {
      console.log(el)
      this.logData = el;
      console.log(this.logData)

    }, err => {
      console.log(err);
    });
  }
}
