import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { Router, CanActivate } from '@angular/router';
import { Subscription } from 'rxjs';
import { RmaAddService } from '../rmaAdd/rmaAdd.service';
declare var $: any;
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login/login.service';
@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {

  constructor(private addService: RmaAddService, private cookieService: CookieService, private activatedRoute: ActivatedRoute,
    protected router: Router, public loginSrc: LoginService) { }

  ngOnInit() {
  }


  public pageTitle: string = 'Welcome';
  public cookieVal: string = "";
  token: string;
  userName = '';
  password = '';
  reportId: string;
  client: any;

  public subscription: Subscription;
  onKey(event: any) { // without type info
    let tar = event.target;
    if (tar.value)
      $(tar).addClass("has-val");
    else
      $(tar).removeClass("has-val");

  }


  async validateLogin() {
    let id = '40'

    // if (($("#txtEmail").val() == "experda") && ($("#txtPass").val() == "experda"))
    //   this.router.navigate(['/historyclient/', id], { relativeTo: this.activatedRoute });
    // else if (($("#txtEmail").val() == "admin") && ($("#txtPass").val() == "683dw63P!")) {
    //   this.cookieService.set("ClientId", "a");
    //   this.router.navigate(['/management/'], { relativeTo: this.activatedRoute });
    // }
    // else {
    let data = { 'Email': $("#txtEmail").val(), 'Password': $("#txtPass").val() };
    this.loginSrc.loginuser(data).subscribe(async (res: any) => {

      if (!res) {
        alert("not found1");
      }
     
      console.log(res.Value)
      if (res.Value) {
        let userdata = {};
        if (res.Value.aUser.Level == 1) {
          userdata = { ...res.Value.aUser, UserLevel: 'admin' };
        }
        else if(res.Value.aUser.Level == 2){
          userdata = { ...res.Value.aUser, UserLevel: 'adminreadonly' };
        }
        await this.loginSrc.setUserData(userdata);
        await this.loginSrc.setTokenData(res.Value.accessToken);

        this.loginSrc.updateSignIn().subscribe((res: any) => {
          
        }, err => {
          console.log(err)
        })
      

        //this.cookieService.set("ClientId", "a");
        this.router.navigate(['/management/'], { relativeTo: this.activatedRoute });
      }
    }, err => {
      console.log(err)
      $.MessageBox("Login Failed");

    })

  }
  //  }
}
