import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit } from '@angular/core';
import { RmaAddService } from './rmaAdd.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;
import Utils from '../helper/helpers'
import { GetSerialsByPartialSerial_Result, GetSerialDetails_Result, RmaComponent, rmaDataCIncludeFiles, GetComponentNameAutoComplete_Result } from "../model/dbContext";
declare var $: any;
import { saveAs } from 'file-saver';
@Component({
  templateUrl: './rmaAddComponentComp.component.html'
})


export class RmaAddComponent implements OnInit, OnDestroy, AfterViewInit {
  getSerail: GetSerialsByPartialSerial_Result;
  serialDetails: GetComponentNameAutoComplete_Result[] = [];
  FileListNames: any[] = [];
  FileListNamesTmp: any[] = [];
  isEdit: boolean = false;
  componentId = "-1";
  clientId = "0";
  component: RmaComponent = new RmaComponent();
  componentDetails: rmaDataCIncludeFiles;
  rmaId: string;
  chooseFromList: boolean = false;
  isEmpty: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private addService: RmaAddService, protected router: Router, private formBuilder: FormBuilder) { }
  ngAfterViewInit() {
    //todo
    
    if ($(".auto").length > 0) {
      var self = this;
     

      var options = {

        url: function (phrase) {
          return "api/SilicomData/GetSerialDetails_ResultForComponent";
        },

        getValue: function (element) {
          self.serialDetails.push(element);
          return element.Name;
        },
        list: {
          onSelectItemEvent: function () {
            $("#txtComponentD").val(self.serialDetails.filter(function (x) { return x.Name == $("#serial-ajax-post").val() })[0].Description);
            self.chooseFromList = true;
           
          },
          onHideListEvent: function () {

          },
          onLoadEvent: function () {
            console.log("loading");
            self.chooseFromList = false;
            $("#imgLoad").css("display", "inline");
            var timerID = setInterval(() => {
              if (self.isEmpty) {
                clearInterval(timerID);
                $("#imgLoad").css("display", "none");
              }
            }, 1000);
          
          },
          onShowListEvent: function () {
            console.log("Finish loading");
            $("#imgLoad").css("display", "none");
          }
          
        },

        ajaxSettings: {
          dataType: "json",
          method: "GET",
          data: {
            tokenId: "json",
            UserId: self.clientId
          },
          success: function (data) {
            if (data.length == 0) {
              $.MessageBox("No Results");
              self.isEmpty = true;
              $("#imgLoad2").css("display", "none");

            }
          }
        },

        preparePostData: function (data) {

          data.partSerial = $("#serial-ajax-post").val();
          return data;
        },
        requestDelay: 400
      };
      (<any>$("#serial-ajax-post")).easyAutocomplete(options);
    }
  }
  onKey(event, num) {
     
      $("#imgLoad").css("display", "inline");
  }
  getParamandSetEdit() {
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.rmaId = this.activatedRoute.snapshot.paramMap.get('rmaId');
    this.isEdit = this.rmaId == "-1" ? false : true;
  }
  uploadFile(files: FileList, clientId: string, componentId: string, rmaId: string) {
    if (files.length == 0) {
      console.log("No file selected!");
      this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
      return;

    }
    let file: FileList = files;

    this.addService.uploadFile(file, clientId, componentId, rmaId)
      .subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            console.log(`File is ${percentDone}% loaded.`);
          } else if (event instanceof HttpResponse) {
            console.log('File is completely loaded!');
          }
        },
        (err) => {
          console.log("Upload Error:", err);
        }, () => {
          console.log("Upload done");
          this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
        }
      )
  }
  redirectBack() {
    this.router.navigate(['/rmaAddNew/', this.clientId, this.rmaId], { relativeTo: this.activatedRoute });
  }
  saveDrafts() {
    $("#imgLoad3").css("display", "inline");
    $("#send").css("display", "none");
    if ($("#serial-ajax-post").val() == "" || $("#txtComponentD").val()=="") {
      $.MessageBox("Component Name or Description Are Missing");
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      return;
    }
    if (!this.chooseFromList) {
      $.MessageBox("Please Choose Component Name From List");
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      return;
    }
    if ($("#txtF").val() == "") {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Please Fill Fallure Description");
      return;

    }

    var files2 = (<HTMLInputElement>document.getElementById('file-2')).files;
    var totalSize = 0;
    for (var x in files2) {
      var filesize = ((files2[x].size / 1024) / 1024).toFixed(4); // MB
      if (!isNaN(parseInt(filesize)))
      totalSize += parseInt(filesize);
      if (parseInt(filesize) > 4) {
        $("#imgLoad3").css("display", "none");
        $("#send").css("display", "inline");
        $.MessageBox("FileS size to big");
       
        return;
      }
    }
    if (totalSize > 10) {
      $("#imgLoad3").css("display", "none");
      $("#send").css("display", "inline");
      $.MessageBox("Files size to big");
      
      return;
    }

    this.component.IsInWarranty = $("#chkinWarrenty").is(":checked");
    this.component.IsDoa = $("#chkDOA").is(":checked");
    this.component.IsMadeInIsrael = $("#chkIsrael").is(":checked");
    this.component.IsSentWithPowerpack = $("#chkPP").is(":checked");
    this.component.FixType = $("#chkFix").val();
    this.component.IssueLevel = $("#chkHard").val();
    this.component.Description = $("#txtF").val();
    this.component.Amount = $("#txtAmount").val();
    this.component.ComponentName = $("#serial-ajax-post").val();
    this.component.ComponentDescription = $("#txtComponentD").val();
    this.component.FailedCard = $("#txtTestType").val();

    //  window["product"].push({ "comp": this.component, "files": files });
    // console.log(window["product"]);
    if (this.isEdit) {//new component
      this.component.Id = parseInt(this.componentId);
      console.log(this.component.Id)
      //  this.component.RmaId = "R0800001";
    }

    this.addService.AddOrUpdateComponentComp(this.component, this.clientId, this.isEdit, this.rmaId).subscribe(el => {
      this.componentId = el;
      var files = (<HTMLInputElement>document.getElementById('file-2')).files;
      this.uploadFile(files, this.clientId, this.componentId, this.rmaId);
    },
      err => console.error(err),
      () => {
      }
    );
  }
  deleteFile() {
    var target = event.target || event.srcElement || event.currentTarget;
    var x = $(target).attr("data-name");
    console.log(target);
    this.FileListNames.splice(this.FileListNames.indexOf($(target).attr("data-name"), 1));
    if (!this.isEdit) {//new component
     
      console.log($(target).attr("data-name"));



    }
    else {
      this.addService.DeleteFile($(target).attr("data-name"), this.clientId, this.componentId,this.rmaId).subscribe(el => {
        console.log(el);
      },
        err => console.error(err),
        () => {
        }
      );
    }

  }
  ngOnInit() {
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.componentId = this.activatedRoute.snapshot.paramMap.get('componentId');
    this.rmaId = this.activatedRoute.snapshot.paramMap.get('rmaId');
    if (this.componentId == "-1")
      this.isEdit = false;
    else//update
    {
      this.chooseFromList = true;
      this.isEdit = true;
      this.fillControls();
      
    }

    //this.component = this.formBuilder.group({
    //  name: ['', Validators.required],
    //  email: ['', Validators.required],
    //  password: ['', Validators.required],
    //  cpassword: ['', Validators.required],
    //  CustomerId: ['', Validators.required],
    //  associated: ['']
    //}); 
  }
  fillControls() {

    //pull data //pull files
    this.addService.getComponentData(this.clientId, this.componentId,true).subscribe(el => {
      this.componentDetails = el;
      this.FileListNames = this.componentDetails.Files ? this.componentDetails.Files : [];
      this.component = this.componentDetails.RmaDetails;

      //typesafe
      $("#chkinWarrenty").attr("checked", this.component.IsInWarranty);
      $("#chkDOA").attr("checked", this.component.IsDoa);
      $("#chkIsrael").attr("checked", this.component.IsMadeInIsrael);
      $("#chkPP").attr("checked", this.component.IsSentWithPowerpack);
      $("#chkFix").val(this.component.FixType);
      $("#chkHard").val(this.component.IssueLevel);
      $("#txtF").val(Utils.typeSafeNull(this.component.Description));
      $("#txtTestType").val(Utils.typeSafeNull(this.component.FailedCard));//
      $("#serial-ajax-post").val(this.component.ComponentName);
      $("#txtComponentD").val(this.component.ComponentDescription);
      $("#txtAmount").val(this.component.Amount);

    },
      err => console.error(err),
      () => {
      }
    );
  }


  uploadChange() {

    var files = (<HTMLInputElement>document.getElementById('file-2')).files;
    let formData = new FormData();
 
    if (!this.isEdit) {
      this.FileListNames = [];
    }
    else {
      for (var f of this.FileListNamesTmp) {
        var index = this.FileListNames.indexOf(f);
        if (index != -1) {
          this.FileListNames.splice(index, 1);
        }

      }
      this.FileListNamesTmp = [];
    }
   
    Array.from(files).forEach(file => { this.FileListNames.push(file.name); this.FileListNamesTmp.push(file.name) });

  }

  DownLoadFiles(data: string) {
    let fileName = data;
    //file type extension
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "txt") {
      fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == "doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == "png") {
      fileType = "image/png";
    }
    if (checkFileType == "jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "gif") {
      fileType = "image/gif";
    }
    if (checkFileType == "csv") {
      fileType = "text/csv";
    }
    this.addService.DownloadFile(fileName, fileType)
      .subscribe(
        success => {
          saveAs(success, fileName);
        },
        err => {
          alert("Server error while downloading file.");
        }
      );
  }
  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    // this.subscription.unsubscribe();

  }
}
