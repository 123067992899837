import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RmahistoryService } from './rmahistory.service';
declare var jQuery: any;
import { getRmahistoryData, GetRMAHistorySilicom_Result } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import * as _ from 'lodash';
declare var $: any;
@Component({
  selector: 'app-backofficermahistory',
  templateUrl: './backofficermahistory.component.html',
  styleUrls: ['./backofficermahistory.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BackofficermahistoryComponent implements OnInit {
  @ViewChild('smallModal', { read: ModalDirective, static: false }) public smModal: ModalDirective;
  rmaForm: FormGroup;
  rmahistorydata: any;
  companyData = [];
  userData = [];
  clientId: string;
  startDate: Date;
  endDate: Date;
  user: any = {};
  constructor(private activatedRoute: ActivatedRoute, private rmahistory: RmahistoryService, protected router: Router, private formBuilder: FormBuilder, ) {

  }




  ngAfterViewInit() {
    this.rmahistory.getUsercompany().subscribe(res => {
      console.log(res);
      this.companyData = res.Value;
    }, err => {
      console.log(err);
      })
    this.serach();
  }
  
  ngOnInit() {
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.rmaForm = this.formBuilder.group({
      Rma: ['', Validators.required],
      fromDate: ['', Validators.required],
      TrackingNumberFilter: ['', Validators.required],
      toDate: ['', Validators.required],
      DeliveryMethodFilter: ['', Validators.required],
      CompanyID: ['', Validators.required],
      RMAStatusFilter: ['', Validators.required],
      RMAStatusFilter2: ['', Validators.required],
      RMAStatusFilter3: ['', Validators.required],
      RMAStatusFilter4: ['', Validators.required],
      RMAStatusFilter5: ['', Validators.required]
    });

    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setMonth(this.startDate.getMonth() - 2);
   
  
  }

  serach() {
   let arr = '';
   if(this.rmaForm.get('Rma').errors)
   //arr += 'Rma detail require <br/>';

   //if(this.rmaForm.get('TrackingNumberFilter').errors)
   // arr += 'Tracking number require <br/>';

   // if(this.rmaForm.get('DeliveryMethodFilter').errors)
   // arr += 'Deliver detail require <br/>';
  
  if($("#fromdata").val() == '')
    arr += 'From date require <br/>';

    if($("#todate").val() == '')
    arr += 'To date require <br/>';

    //if(this.rmaForm.get('CompanyID').errors)
    //arr += 'Company name require <br/>';   
      //if(this.rmaForm.get('RMAStatusFilter').errors)
      //arr += 'Status require <br/>';  

    if(arr != ''){
      $.MessageBox(arr);
    }else{
      let payload = this.rmaForm.value;
      payload.UserId = this.clientId;
      payload.fromDate=$("#fromdata").val();
      payload.toDate=$("#todate").val();
      payload.RMAFilter = null;
      payload.RMAStatusFilter = '';
      payload.RMAPriorityStatusFilter = null;
   
 
      $(".topC input:checked").each(function () {
        if (this.checked) {
          payload.RMAStatusFilter+=$(this).val()+",";
        }
      });
   
      
      console.log(payload);
      this.rmahistory.getRmaHistory(payload).subscribe(el => {
          this.rmahistorydata  = el;
        console.log(this.rmahistorydata);
        // if(!this.rmahistorydata.length){
        //   this.serach();
        // }
      },
        err => console.error(err),
        () => {
         
        
        }
      );
      }
  }


  serachrmadata(){
    console.log(this.rmaForm.value);
    let payload = this.rmaForm.value;
      payload.UserId = this.clientId;
      payload.fromDate=$("#fromdata").val();
      payload.toDate=$("#todate").val();
      payload.RMAFilter = null;
      payload.RMAStatusFilter = '';
      payload.RMAPriorityStatusFilter = null;
      console.log(payload);

  }

  Update(data): void {
    console.log(data);
    this.user.Status = data.Status1;
    this.user.Id = data.RMA_;
    this.smModal.show();
  }
  redirectToView(data) {
    if (data.Is_Old_RMA) {
      $.MessageBox("RMA FOR VIEW ONLY");
      return;
    }
    this.router.navigate(['/newrma/', data.RMA_], { relativeTo: this.activatedRoute });
  }

  ok() {
     console.log(this.user.Status);
     console.log(this.user.Id);

    this.rmahistory.updatermahistoryStatus(this.user).subscribe(el => {
      console.log(el);
      this.smModal.hide();
      var a = this.rmahistorydata.find(val => val.RMA_ == el.Id);
      if (a){
      a.Status1 =el.Status;
    }
  },
      err => console.error(err),
      () => {
      }
    );

  }


getFormValidationErrors() {
  
  Object.keys(this.rmaForm.controls).forEach(key => {

  const controlErrors: ValidationErrors = this.rmaForm.get(key).errors;
  if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });


  }


}
