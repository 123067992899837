import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewChild } from '@angular/core';
import { RmahistoryService } from './rmahistory.service';
declare var jQuery: any;
import { getRmahistoryData } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-rmahistoryclient',
  templateUrl: './rmahistoryclient.component.html',
  styleUrls: ['./rmahistoryclient.component.css']
})
export class RmahistoryclientComponent implements OnInit {
  rmahistoryForm: FormGroup;
  rmahistorydata: any;
  clientId: string;
  constructor(private activatedRoute: ActivatedRoute, private rmahistory: RmahistoryService, protected router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
   this.clientId= this.activatedRoute.snapshot.paramMap.get('clientId');
    this.rmahistoryForm = this.formBuilder.group({
      RMAFilter: ['', Validators.required],
      TrackingNumberFilter: ['', Validators.required],   
      DeliveryMethodFilter: ['', Validators.required],
    });
    this.serach();
  }




  
  serach() {

    let arr = '';
   //if(this.rmahistoryForm.get('RMAFilter').errors)
   //arr += 'Rma detail require <br/>';

   //if(this.rmahistoryForm.get('TrackingNumberFilter').errors)
   // arr += 'Tracking number require <br/>';

    //if($("#fromdate").val() == '')
    //arr += 'From date require <br/> ';

    //if($("#todate").val() == '')
    //arr += 'To date require <br/>';

   // if(this.rmahistoryForm.get('DeliveryMethodFilter').errors)
   // arr += 'Deliver detail require <br/>';
  
 

    if(arr != ''){
      $.MessageBox(arr);
    }else{
      
    let payload = this.rmahistoryForm.value;
      payload.UserId = this.clientId;
      payload.fromDate = $("#fromdate").val() ? $("#fromdate").val() :null;
      payload.toDate = $("#todate").val() ? $("#todate").val() :null;
    console.log(payload);
    this.rmahistory.getRmaHistory(payload).subscribe(el => {
      console.log(el);
      this.rmahistorydata = el;
      if (el.length == 0)
        $.MessageBox("No records found");
      console.log(this.rmahistorydata);
    },
      err => console.error(err),
      () => {
      }
    );
  }
}

  redirectToRmaHistory(id,  isOld?:Boolean) {
    if (isOld) {
      $.MessageBox("RMA FOR VIEW ONLY");
      return;
    }
    this.router.navigate(['/rmaAddNew/', this.clientId,id], { relativeTo: this.activatedRoute });
  }

  getFormValidationErrors() {
  
    Object.keys(this.rmahistoryForm.controls).forEach(key => {
  
    const controlErrors: ValidationErrors = this.rmahistoryForm.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
  
  
    }


}
