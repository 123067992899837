

import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NoInternet, AuthFail, BadInput, AccessDenied, ServerError, DefaultError, NotFound, Conflict, NotAcceptable, TimeoutError } from './error.class';
import 'rxjs/add/operator/timeout';
import { tap } from 'rxjs/operators/tap';
import { catchError } from 'rxjs/operators/catchError';
import { Router } from '@angular/router';
//import { AlertController } from '@ionic/angular';
import { retryWhen, delayWhen } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { LoginService } from '../login/login.service';
declare var $: any;

@Injectable()
export class IntercepterService implements HttpInterceptor {
  constructor(public loginSrc: LoginService, public router: Router, public ut: UtilityService) { }

  private handleError(err: HttpErrorResponse) {
    console.log(err);
    let appError;
    if (err && err.message == 'Timeout has occurred') {
      appError = new TimeoutError(err);
    } else if (err instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        appError = new NoInternet(err);
      } else {
        switch (err.status) {
          case 0:
            appError = new NoInternet(err);
            break;
          case 401:
            //this.dp.SignOut();
            appError = new AuthFail(err);
            break;
          case 400:
            appError = new BadInput(err);
            break;
          case 403:
            //  this.dp.SignOut();
            appError = new AccessDenied(err);
            break;
          case 404:
            appError = new NotFound(err);
            break;
          case 409:
            appError = new Conflict(err);
            break;
          case 406:
            appError = new NotAcceptable(err);
            break;
          case 500:
            appError = new ServerError(err);
            break;
          default:
            appError = new DefaultError(err);
        }
      }
    }
    console.log('app error', appError);
    return throwError(appError);
    //  return Observable.throw(appError);
  }

  promptForRetry(error, title, msg) {
    const retrySubject = new Subject();
    let retry;
    this.ut.hideLoading();
    setTimeout(async () => {
      $.MessageBox(msg);
      //this.ut.showToast(null, title, msg);
      // retry = await this.showToast.create({
      //   header: title,
      //   message: msg,
      //   cssClass: 'apiAlert',
      //   buttons: [
      //     {
      //       text: 'No',
      //       handler: () => {
      //         retry.dismiss();
      //         retrySubject.error(error);
      //         retrySubject.complete();
      //         return false;
      //       }
      //     },
      //     {
      //       text: 'Retry',
      //       handler: () => {
      //         retry.dismiss();
      //         retrySubject.next(1);
      //         retrySubject.complete();
      //         return false;
      //       }
      //     }
      //   ]
      // });
      // await retry.present();
    }, 1);



    return retrySubject;
  }
  isTokenTobeAdd(url) {
    const re = ['login', 'apiDefault', 'json'];
    let checkurl = url.split('/');
    if (re.indexOf(checkurl[checkurl.length - 1]) == -1)
      return true;
    else
      return false;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const token = this.loginSrc.getTokenData();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    if (this.isTokenTobeAdd(request.url) && token) {
      request = request.clone({
        // headers: req.headers
        //   .set('Authorization', `Bearer ${token}`)
        //   .set('timezone', new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1])
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    // return next.handle(request);
    return next.handle(request).timeout(3000000).pipe(
      retryWhen(errors => {
        return errors.pipe(
          delayWhen(error => {
            return error.name == 'TimeoutError' ? this.promptForRetry(error, 'Error', 'Request taking more time than expected, please check your connection') : !navigator.onLine || error.status == 0 ? this.promptForRetry(error, 'Error', 'Please check your connection') : this.handleError(error);
          })
        );
      }),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }),
      catchError((err) => {
        return this.handleError(err);
      })
    );
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: IntercepterService, multi: true }
  ]
})
export class InterceptorModule { }


