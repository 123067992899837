
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable()
export class UserService implements CanActivate {

  constructor(private router: Router, public loginsrc: LoginService) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {
    const token = await this.loginsrc.getTokenData();
    const userData = this.loginsrc.getUserData();

    if (token !== undefined && userData != undefined && userData.UserLevel && userData.UserLevel == 'rmauser') {
    
        console.log(token);
        console.log(userData.UserLevel)
        //this.router.navigate(['/historyclient/', userData.Id]);
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
   
  }




}





