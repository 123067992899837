import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewChild } from '@angular/core';
declare var jQuery: any;
import { getRmahistoryData } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NewrmaserviceService } from './newrmaservice.service';
import { RmaAddService } from '../rmaAdd/rmaAdd.service';
import { saveAs } from 'file-saver';
import { retry } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UsermanagementService } from '../backofficeusermanagement/usermanagement.service';
declare var $: any;
@Component({
  selector: 'app-newrma',
  templateUrl: './newrma.component.html',
  styleUrls: ['./newrma.component.css']
})
export class NewrmaComponent implements OnInit {
  @ViewChild('smallModal', { read: ModalDirective, static: false }) public smModal: ModalDirective;
  @ViewChild('informationModal', { read: ModalDirective, static: false }) public infoModal: ModalDirective;


  getRmaData = [];
  getrmaDetail = [];
  getrmaComponent = [];
  private id: Subscription;
  updatedata: any = {};
  FileListNames: any[] = [];
  infoForm: FormGroup;
  infodata: any = {};
  detailname: any;
  selectedLevel;
  infoUser: {} = {};
  clientId: string;

  constructor(private cookieService: CookieService, private userService: UsermanagementService, private activatedRoute: ActivatedRoute, private addService: RmaAddService, private newrma: NewrmaserviceService, protected router: Router, private formBuilder: FormBuilder) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.infoForm = formBuilder.group({
      Dis_Approve_Reason: ['', Validators.required],


    });
  }
  ngOnInit() {
    this.getrmaData();
    

  }

  getUserInfo() {
    this.clientId = this.getRmaData["UserId"];
    console.log("this.getRmaData", this.getRmaData)
    this.userService.getUserInfo(this.clientId).subscribe(el => {
      if (el) {
        var a = el.Value;
        this.infoUser["Email"] = a.Email;
        this.infoUser["Phone"] = a.Phone;
        this.infoUser["Address"] = a.Address;
      }
    });
      }

  getrmaData() {
    this.newrma.getrma(this.id).subscribe((res: any) => {
      this.getRmaData = res.Value;
      console.log(this.getRmaData);
      this.getUserInfo();
    }, err => {
      console.log(err);
    })

    this.newrma.getRmaDetails(this.id).subscribe((res: any) => {
      console.log(res)
      this.getrmaDetail = res.Value;
      this.getrmaDetail.forEach((item, index) => {
        console.log(this.getFilesCount(item.Id, false, item));
        
      })
      

    }, err => {
      console.log(err);
    })

    this.newrma.getRmaComponets(this.id).subscribe((res: any) => {
      console.log(res);
      this.getrmaComponent = res.Value;
      this.getrmaComponent.forEach((item, index) => {
        console.log(this.getFilesCount(item.Id, true, item));

      })

    }, err => {
      console.log(err);
    })
  }

  viewFiles(data,isC:boolean): void {
    console.log(data);
    //if (isC) {
      this.newrma.GetFileForComponent(data).subscribe((res: any) => {

        this.FileListNames = res;
        console.log(this.FileListNames);
        this.smModal.show();
      }, err => {
        console.log(err);
      })
    //}
    //else {
    //  this.newrma.GetFileProduct(data).subscribe((res: any) => {

    //    this.FileListNames = res.Value;

    //    this.smModal.show();
    //  }, err => {
    //    console.log(err);
    //  })
    //}


  }
  ok() {
      this.smModal.hide();

  }
  getFilesCount(id,isC,item) {
    if (isC) {
      this.newrma.GetFileForComponentCount(id).subscribe((res: any) => {
        item["fileCount"] = res;
      }, err => {
        console.log(err);
      });
    }
    else {
      this.newrma.GetFileForProductCount(id).subscribe((res: any) => {
        item["fileCount"] = res;
      }, err => {
        console.log(err);
      });
    }
  }
  DownLoadFiles(data: string) {
    let fileName = data;
    //file type extension
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "txt") {
      fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == "doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == "png") {
      fileType = "image/png";
    }
    if (checkFileType == "jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "gif") {
      fileType = "image/gif";
    }
    if (checkFileType == "csv") {
      fileType = "text/csv";
    }
    this.addService.DownloadFile(fileName, fileType)
      .subscribe(
        success => {
          saveAs(success, fileName);
        },
        err => {
          alert("Server error while downloading file.");
        }
      );
  }

  updateStatus(val) {
    $("#imgLoad").css("display", "inline");
    this.updatedata.Status = val;
    this.updatedata.Id = this.id;
    this.updatedata.RmaType = $("#selectedLevel").val();
    
    this.updatedata.IsCredit = $("#chk5").is(":checked");
    console.log(this.updatedata)
    this.newrma.statusUpdate(this.updatedata).subscribe((res: any) => {
      console.log(res);
      this.getRmaData = res;
      $.MessageBox("Update Status Success");
      $("#imgLoad").css("display", "none");

    }, err => {
      console.log(err);
    })


  }


  updatechecklist(val, e, detail) {
    console.log(detail);
    let data: any = {};
    data.Id = val.Id;
    data.e = e;
    data.detail = detail.target.checked;
    this.newrma.updateDetailChecklist(data).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    })
  }


  updateComponentChecklist(e, val, detail) {
    let data: any = {};
    data.Id = val.Id;
    data.e = e;
    data.detail = detail.target.checked;
    this.newrma.updateComponetnChecklist(data).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    })
  }


  // ****Features******
  disapprove(name, data, e){
    console.log(name, data, e);
    console.log(e.target.checked)
    this.detailname= name;
    console.log(this.detailname);
    this.infodata.Dis_Approve = e.target.checked;
    this.infodata.Id = data.Id;
    console.log(e.target.checked)
if(e.target.checked){
  this.infoModal.show();
} else {
  this.infodata.Dis_Approve_Reason = "";
  this.savemessage();
}
  }


  savemessage(){
    console.log(this.infodata);
    
    if(this.detailname == 'component'){     
      this.newrma.updateComponentdisapprove(this.infodata).subscribe((res:any)=>{
        console.log(res);
        this.infoModal.hide();

      },err=>{
        console.log(err);
        this.infoModal.hide();

      })
    } else{
      this.newrma.updateproductdisapprove(this.infodata).subscribe((res:any)=>{
        console.log(res);
        this.infoModal.hide();

      },err=>{
        console.log(err);
        this.infoModal.hide();

      })
    }
    

  }


}
