import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UsermanagementService } from '../backofficeusermanagement/usermanagement.service';
declare var $: any;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
 
  public registerForm: FormGroup;
  registerdata: any = {};

  constructor(public router: Router, public formBuilder: FormBuilder, private rmahistory: UsermanagementService) {
    this.registerForm = formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      Company_Name_Tmp: ['', Validators.required],
      Address: ['', Validators.required],
      Name: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onKey(event: any) { // without type info
    let tar = event.target;
    if (tar.value)
      $(tar).addClass("has-val");
    else
      $(tar).removeClass("has-val");

  }

  register() {

    if (this.registerForm.invalid) {
      $.MessageBox("There are some errors on the form");
      return false;
    }
    if ((this.registerdata.password != this.registerdata.confirmpassword)) {
      $.MessageBox("Password and confirm password don't match");
      return false;
    }


    this.registerdata.Is_Approve = false;
    console.log(this.registerdata)
    $(".login100-form-btn").hide();
    $("#imgLoad").show();
    
    this.rmahistory.aditOrUpdate(this.registerdata).subscribe((res:any) => {
      console.log(res);
      $.MessageBox("Your account is created, please wait for administrator to approve your account");
      
      setTimeout(() => this.redirect(),5000);   
    }, err => {
      console.log(err);
      $(".login100-form-btn").hide();
      $("#imgLoad").show();
      $.MessageBox(err.error.Value);
    })
  }
  redirect() {
    this.router.navigate(['/login']);
}

 

}
