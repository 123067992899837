import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Rma, RmaDetails, ReduceComponentDetails } from "../model/dbContext";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { UsermanagementService } from '../backofficeusermanagement/usermanagement.service';
import { LoginService } from '../login/login.service';
declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './master.component.html',
  encapsulation: ViewEncapsulation.None
})

export class MasterComponent {
  clientId: any;
  constructor(public router: Router, public loginSrc: LoginService, private userService: UsermanagementService, private activatedRoute: ActivatedRoute, private cookieService: CookieService, private formBuilder: FormBuilder) {
    this.infoForm = formBuilder.group({

      Password: ['', Validators.required],
      Confirmpassword: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }
  infoForm: FormGroup;
  infoUser: {} = {};
  @ViewChild('infoModal', { read: ModalDirective, static: false }) public smModal: ModalDirective;

  ngOnInit() {
    //this.clientId = this.cookieService.get("ClientId");
    this.clientId = this.loginSrc.getUserData();

    if (this.clientId.UserLevel == "admin" || this.clientId.UserLevel == 'adminreadonly') {
      $("#liLogin").css("display", "none");

    } else {

      this.userService.getUserInfo(this.clientId.Id).subscribe(el => {
        if (el) {
          var a = el.Value;
          this.infoUser["Email"] = a.Email;
          $("#txtEmail1").val(a.Email);
          $("#txtPhone1").val(a.Phone);
          $("#txtPass1").val(a.Password);
          $("#txtReturnAddress1").val(a.Address);
          $("#txtConfirmPass1").val(a.Password);
          this.infoUser["Phone"] = a.Phone;
          this.infoUser["Password"] = a.Password;
          this.infoUser["ConfirmPassword"] = a.Password;
        }
      },
        err => console.error(err),
        () => {
        }
      );
    }

  }
  openInfo() {
    this.smModal.show();

  }
  ok() {
    if (($("#txtPass1").val() == "")) {
      alert("Password can't be empty");
      return false;
    }

    if (($("#txtPass1").val() != $("#txtConfirmPass1").val())) {
      alert("Password and confirm password don't match");
      return false;
    }
    this.infoUser["Phone"] = $("#txtPhone1").val();
    this.infoUser["Password"] = $("#txtPass1").val();
    this.infoUser["Address"] = $("#txtReturnAddress1").val();

    this.userService.updateUser(this.infoUser).subscribe((res: any) => {
      console.log(res);
      $.MessageBox("Settings Saved");
    }, err => {
      console.log(err);
      $(".login100-form-btn").hide();
      $("#imgLoad").show();
      $.MessageBox(err.error.Value);
    })

    this.smModal.hide();
  }

  async signout() {
    const token = this.loginSrc.getTokenData();
    const userData = this.loginSrc.getUserData();

    this.loginSrc.updateSignOut().subscribe((res: any) => {

    }, err => {
      console.log(err)
    })
 

    console.log(userData)
    if (userData.UserLevel == 'rmauser') {
      this.router.navigate(['login'])
    } else {
      this.router.navigate(['/admin/login']);

    }
    await localStorage.removeItem('token')
    await localStorage.removeItem('userDetail')
  }
}
