import { Injectable, Inject } from '@angular/core';
import { Jsonp, URLSearchParams, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { getRmahistoryData } from "../model/dbContext";
import { catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { config } from '../app.global';


@Injectable({
  providedIn: 'root'
})
export class RmahistoryService {
  baseUrl: string;
  //baseUrl: string = config.baseUrl;

  //baseUrl: string = "http://192.168.0.104:8003/";
  constructor(protected router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this.baseUrl = baseUrl; }
  //constructor(protected router: Router,private http: HttpClient) {  }




  getRmaHistory(data) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.post<getRmahistoryData[]>(this.baseUrl + 'api/RMAHistoryClient/GetRMAHistoryClient', data).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));


  }


  private handleError(error: Response, funcName: string, freeText: string, clientId?: string, serverName = "0") {

    if (clientId == "")
      clientId = "0";
    var errors = error.text();

    if (error.text().indexOf("Token doesn't exist") > -1) {
      console.log("Token doesn't exist \n redirect to login");
      this.router.navigate(['/login']);//cookie doesn't exist->back to login       
    }

    //create async writes to log
    var a = this.WriteToClientLog(funcName.split("(")[0], freeText, "test", "").subscribe(el => console.log(el),
      err => console.error(err),
      () => console.log("Processing Complete.")
    );

    return Observable.throw(error.text() || 'Server error');
  }
  WriteToClientLog(exp: string, text: string, tokenId: string, logFileName: string) {
    const params = new HttpParams().set('tokenId', "test").set('text', text).set('exp', exp)
      .set('logFileName', logFileName);
    return this.http.get<getRmahistoryData[]>(this.baseUrl + 'api/SilicomData/writeLog', { params });

  }


}
