//sp
 export  class GetRMADetailsClient_Result {
  Serial: string;
  Part_ID: number;
  Part_Name: string;
  Part_Desc: string;
  Part_Customer_Desc: string;
  Is_In_Warranty: boolean;
  Is_DOA: boolean;
  Is_Made_In_Israel: boolean;
  Is_Sent_With_Powerpack: boolean;
  Fix_Type: string;
  Description: string;
}
 export class GetRMADetailsSilicom_Result {
  ID: number;
  RMA_ID: string;
  Part_ID: number;
  Serial: string;
  Part_Invoice_Price: number | null;
  Invoice_ID: string;
  HTS: string;
  Fix_Type: string;
  Issue_Level: string;
  Description: string;
  Failed_Card: string;
  Board_Failed: string;
  Slot_Number: number ;
  Is_In_Warranty: boolean;
  Is_DOA: boolean;
  Is_Made_In_Israel: boolean;
  Is_Sent_With_Powerpack: boolean;
  Part_Name: string;
  Part_Desc: string;
  Part_Customer_Desc: string;
}
export class GetComponentNameAutoComplete_Result {
  Name: number;
  Description: string;
  Is_In_Warranty: string;
}
   export class GetRMAHistoryClient_Result {
  ID: string;
  RMA_Type: string;
     Create_Date: Date | string;
     Approval_Date: Date | string;
Is_Out_Of_Warranty_RMA: boolean;
Status: string;
     Items: number;
     Is_Old_RMA: boolean | null;
}

export class GetRMAHistoryClientAll_Result {
  ID: string;
  RMA_Type: string;
  Create_Date: Date | string;
Approval_Date: Date | null;;
Is_Out_Of_Warranty_RMA: boolean | null;
Status: string;
Items: number | null;
}
 export  class GetRMAHistorySilicom_Result {
  RMA_: string;
  Company_Name: string;
   Date: Date| null;
Status: string;
Status1: string;
Contact_Name: string;
Email: string;
Items: number | null;
RMA_Type: string;
OOW: boolean | null;
   Total: number | null;
   Is_Old_RMA: Boolean;
    }
 export  class GetSerialDetails_Result {
  Part_Serial: string;
  HTS: string;
  Invoice_ID: string;
  Delivery_Date: Date | null;
  Part_ID: number;
  Part_Name: string;
  Part_Desc: string;
  Part_Customer_Desc: string;
  Price_Per_Unit: number | null;
   Teudat_Mishloch_ID: string;
   Is_In_Warranty: string;
}
  export  class GetSerialsByPartialSerial_Result {
  Customer_ID: number | null;
  Part_Serial: string;
}
 export  class GetSerialsByPartNo_Result {
  Part_ID: number;
  Part_Serial: string;
}
 export  class GetSerialsByTrackingNo_Result {
  Part_ID: number;
  Part_Serial: string;
}

//tables
export class Rma {
  Id: string;
  CustomerId: number | null;
  UserId: number ;
  RmaType: string;
  CreateDate: Date | string;
  Status: string;
  PriorityStatus: string;
  IsCredit: boolean;
  ApprovalDate: Date | string | null;
  TrackingNumber: string;
  DeliveryMethod: string;
  ContactName: string;
  ContactEmail: string;
  ContactPhone: string;
  CompanyName:string;
  ContactReturnAddress: string;
  IsOutOfWarrantyRma: boolean | null;
  Is_Old_RMA: boolean | null;
  Customer: Customers;
  User: Users;
  RmaDetails: RmaDetails[];
}
export class Customers {
  Id: number;
  Name: string;
  Description: string;
  Invoices: Invoices[];
  Rma: Rma[];
  Users: Users[];
}
export class Users {

  Id: number;
  Email: string;
  Password: string;
  CustomerId: number | null;
  Phone: string;
  Address: string;
  Customer:Customers ;
}




export  class RmaDetails {
  Id: number;
  RmaId: string;
  PartId: number;
  Serial: string;
  PartInvoicePrice: number | null;
  InvoiceId: string;
  Hts: string;
  FixType: string;
  IssueLevel: string;
  Description: string;
  FailedCard: string;
  BoardFailed: string;
  SlotNumber: number | null;
  IsInWarranty: boolean;
  IsDoa: boolean;
  IsMadeInIsrael: boolean;
  IsSentWithPowerpack: boolean;
  Part: Parts;
  Part_Name: string | null;
  Delivery_Date: string | null;
  Rma: Rma;
}
 export class rmaDataIncludeFiles {
  RmaDetails: RmaDetails;
         Files:string[] 
}
export class RmaComponent
{
  Id: number;
  RmaId: string;
  ComponentName: string;
  ComponentDescription: string;
  Amount: number;
  FixType: string;
  IssueLevel: string;
  FailedCard: string;
  Description: string;
  IsInWarranty: boolean;
  IsDoa: boolean;
  IsMadeInIsrael: boolean;
  IsSentWithPowerpack: boolean;
}
export class rmaDataCIncludeFiles {
  RmaDetails: RmaComponent;
  Files: string[]
}
export class ReduceComponentDetails {
  Id: number;
  Serial: string;
  Component_Name: string;
  IssueLevel: string;
  IsInWarranty: boolean;
  IsDoa: boolean;
  IsMadeInIsrael: boolean;
  IsSentWithPowerpack: boolean;
  RmaId: string;
  isC: boolean;

}


export class TeudatMishloch {
  Id: string;
  Date: Date | string | null;
  CustomerId: number | null;
  TeudatMishlochDetails: TeudatMishlochDetails[];
}

export class TeudatMishlochDetails {
  TeudatMishlochId: string;
  PartId: number;
  PartName: string;
  PartDesc: string;
  PartCustomerDesc: string;
  PartSerial: string;
  PricePerUnit: number | null;
  Hts: string;
  Currency: string;
  Id: number;
  Part: Parts;
  TeudatMishloch: TeudatMishloch;
}
export class Parts {
  Id: number;
  Name: string;
  Description: string;
  InvoiceDetails: InvoiceDetails[];
  RmaDetails: RmaDetails[];
 TeudatMishlochDetails: TeudatMishlochDetails[];
}
export class InvoiceDetails {
  InvoiceId: string;
  PartId: number;
  PartName: string;
  PartDesc: string;
  PartCustomerDesc: string;
  PartSerial: string;
  Price: number | null;
  PricePerUnit: number | null;
  Hts: string;
  Currency: string;
  Id: number;
  Part: Parts[];
}
export  class Invoices {
  Id: string;
  InvoiceType: string;
  CustomerId: number | null;
  Date: Date | string | null;
  Final: string;
  TrackingNumber: string;
  TotalPrice: number;
  Customer: Customers[];
}



export  class GetUsers_Result {
  RMA_Count: number | null;
  Last_RMA_Date: Date | null;
  Last_Delivery_Date: Date | null;
  ID: number;
  Email: string;
  Password: string;
  Customer_ID: number | null;
  Phone: string;
  Address: string;
  cpassword: string;
  Is_Approve: boolean;
}



export class editoradd {
  Id: number;
  Email: string;
  Password: string;
  cpassword: string;
  CustomerId: number | null;
  Name:string;
  Phone: string;
  Address: string;
  Customer:Customers ;
  Rma:Rma[];
  associated:boolean;
}


export class  getRmahistoryData{
  UserId: number;
  CompanyID: number | null;
  fromDate: Date | string;;
  toDate: Date | string;;
  RMAFilter: null;
  TrackingNumberFilter: null;
  DeliveryMethodFilter: null;
  RMAStatusFilter: string;
  RMAPriorityStatusFilter: string;
  Is_Old_RMA: boolean | null;
}

export class AdminUser {

  Id: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  Level: number | null;
  Active: boolean | null;
  CreatedOn: Date | string | null;
  UpdatdOn: Date | string | null;

}

export class RmaComponentAdd {

  tokenId: string;
  clientId: string;
  componentNumber: RmaDetails;
  isEdit: boolean;
  rmaId: string;

}
