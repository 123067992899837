import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})


export class CheckloginGuard implements CanActivate {

  constructor(private router: Router, public loginSrc: LoginService) {

  }

  async canActivate() {
    const token = this.loginSrc.getTokenData();
    const userData = this.loginSrc.getUserData();
    console.log(token);
    if (token != undefined) {
      if (token) {
        if (userData.UserLevel == 'rmauser') {
          this.router.navigate(['/historyclient/', userData.Id]);
        } else {
          this.router.navigate(['/management/']);
        }
        return false;
      }
    } else {
      return true;
    }
    return true;
  }


}
