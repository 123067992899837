import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


declare var jQuery: any;
@Injectable()
export class Guard implements CanActivate {

  reportId: string;
  clientId: string;
  typeB: string;
  public subscription: Subscription;
  constructor(protected router: Router, private activatedRoute: ActivatedRoute, private cookieService: CookieService) { }

  canActivate() {
    if (this.cookieService.get("ClientId"))
      return of(true);
    else
      return of(false);
  }
}
