import { Injectable, Inject } from '@angular/core';
import { Jsonp, URLSearchParams, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { getRmahistoryData } from "../model/dbContext";
import { catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { config } from '../app.global';

@Injectable({
  providedIn: 'root'
})
export class ActivitylogService {

  baseUrl: string;
  //baseUrl: string = config.baseUrl;
  //baseUrl: string = "http://192.168.0.104:8003/";
  constructor(protected router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this.baseUrl = baseUrl; }

  // constructor(protected router: Router, private http: HttpClient) { 
  //   console.log(this.baseUrl)
  // }

  getActivityLog(data) {
    var stack = new Error().stack,
      funcNameLog = stack.split('\n')[1].trim();
    var freeTextLog = "testApi service";

    return this.http.post<any[]>(this.baseUrl + 'api/EventLog/GetLogs', data).pipe(
      catchError(err => {
        var stack = new Error().stack,
          funcNameLog = stack.split('\n')[1].trim();
        var freeTextLog = "testApi service";
        return of(null);
      }));

  }

}
