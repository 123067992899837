

import { Injectable } from '@angular/core';
//import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  loading: boolean = false;
  // position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //   constructor(private toastrService: NbToastrService) { }

  showLoading() {
    this.loading = true;
  }
  hideLoading() {
    this.loading = false;
  }

  // showToast(type: NbComponentStatus, title: string, body: string, duration:number = 3000) {
  //   const config = {
  //     status: type,
  //     destroyByClick: true,
  //     duration: duration,
  //     hasIcon: true,
  //     //position: this.position,
  //     preventDuplicates: true,
  //   };
  //   const titleContent = title ? `${title}` : '';    
  //   this.toastrService.show(
  //     body,
  //     titleContent,
  //     config);
  // }
}

