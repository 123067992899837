
import { Injectable, Inject } from '@angular/core';
import { Jsonp, URLSearchParams, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { getRmahistoryData } from "../model/dbContext";
import { catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { config } from '../app.global';



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl: string;
  //baseUrl: string = config.baseUrl;
  //baseUrl: string = "http://192.168.0.104:8003/";
  constructor(protected router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this.baseUrl = baseUrl; }

  //constructor(protected router: Router, private http: HttpClient) {}

  getTokenData() {
    return JSON.parse(localStorage.getItem('token'));
  }
  setTokenData(token) {
    return localStorage.setItem('token', JSON.stringify(token));
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('userDetail'));
  }

  setUserData(userdetail) {
    return localStorage.setItem('userDetail', JSON.stringify(userdetail));
  }


  loginuser(data) {
    return this.http.post(this.baseUrl + 'api/user/login', data)
  }

  resiterAdmin(data) {
    return this.http.post(this.baseUrl + 'api/user/RegisterUser', data);
  }

  getAdminUser() {
    return this.http.get(this.baseUrl + 'api/user/GetUserDetail');
  }

  updateSignOut() {
    return this.http.get(this.baseUrl + 'api/user/updateSignOut');
  }
  updateSignIn() {
    return this.http.get(this.baseUrl + 'api/user/updateSignIn');
  }




  


  deleteAdminUser(Id) {
    return this.http.delete(this.baseUrl + 'api/user/DeleteUser/' + Id);
  }
}
