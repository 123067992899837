export class NoInternet {
    public status: number;
    public msg: string;
    constructor(error: any) {
        this.msg = 'No internet connection';
        this.status = 1;
    }
}

export class AuthFail {
    public status: number;
    public msg: string;
    constructor(error: any) {
        this.msg = 'User authentication fail';
      this.status = error.status;
      alert('User authentication fail');
    }
}


export class AccessDenied {
    public status: number;
    public msg: string;
  constructor(error: any) {
    alert('User authentication fail or you dont have permissions for this action ');
        this.msg = error.error.errors.messages[0] || 'User not authorised';
        this.status = error.status;
    }
}

export class BadInput {
    public status: number;
    public msg: string;
    constructor(error: any) {
        this.msg = error.error.error.message[0] || 'Bad Request';
        this.status = error.status;
    }
}

export class NotFound {
    public status: number;
    public msg: string;
    constructor(error: any) {
        console.log(error);
        this.msg = error.error.errors.messages[0] || 'Request url is wrong';
        this.status = error.status;
    }
}

export class NotAcceptable {
    public status: number;
    public msg: string;
    constructor(error: any) {
        this.msg = error.errors.message || 'Barcode is wrong';
        this.status = error.status;
    }
}

export class Conflict {
    public status: number;
    public msg: string;
    constructor(error: any) {
        this.msg = 'Request Failed: ' + error.error.value;
        this.status = error.status;
    }
}


export class ServerError {
    public status: number;
    public msg: string;
    constructor(error: any) {
        if (error.status == 0) {
            this.msg = 'Server not responding or busy.';
        } else {
            this.msg = 'There is something wrong with server.';
        }
        this.status = error.status;
    }
}

export class DefaultError {
    public status: number;
    public msg: string;
    constructor(error: any) {
        console.log(error);
        this.msg = error.error.error || 'Something bad happened, please retry';
        this.status = error.status;
    }
}

export class TimeoutError {
    public status: number;
    public msg: string;
    constructor(error: any) {
        console.log(error);
        this.msg = 'Server not responding.';
        this.status = 2;
    }
}

