import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { RmaAddService } from '../rmaAdd/rmaAdd.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;
import Utils from '../helper/helpers'
import { Rma, RmaDetails, ReduceComponentDetails, rmaDataIncludeFiles, RmaComponent, rmaDataCIncludeFiles } from "../model/dbContext";
declare var $: any;
@Component({
  templateUrl: './fileBrowse.component.html',
  encapsulation: ViewEncapsulation.None
})

export class FileBrowse implements OnInit, OnDestroy, AfterViewInit {
  clientId: string;
  rmaId: string;
  rmaD: Rma;
  componentDetails: rmaDataIncludeFiles[];
  component: RmaDetails = new RmaDetails();
  rmaComponentsList: ReduceComponentDetails[] = [];
  component2: RmaComponent = new RmaComponent();
  componentDetails2: rmaDataCIncludeFiles[];
  FileListNames: any[] = [];
 
  constructor(private activatedRoute: ActivatedRoute, private addService: RmaAddService, protected router: Router, private formBuilder: FormBuilder) { }
  ngAfterViewInit() {



  }
  ngOnInit() {

    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.rmaId = this.activatedRoute.snapshot.paramMap.get('rmaId');

    //get components list
    this.addService.getComponentList(this.clientId, this.rmaId).subscribe(el => {
      this.rmaComponentsList = el;
      for (let o of this.rmaComponentsList) {
        if (o.isC)
          this.addService.getComponentData(this.clientId, o.Id.toString()).subscribe(el => {
            this.componentDetails = el;
          //  this.FileListNames = this.componentDetails.Files ? this.componentDetails.Files : [];
            //this.FileListNames = this.componentDetails.Files ? this.componentDetails.Files : [];
            //this.component = this.componentDetails.RmaDetails;
          });
        else
          //pull data //pull files
          this.addService.getComponentData(this.clientId, o.Component_Name, true).subscribe(el => {
            this.componentDetails2.push(el);
            //this.FileListNames = this.componentDetails.Files ? this.componentDetails.Files : [];
            //this.component = this.componentDetails.RmaDetails;
          },
            err => console.error(err),
            () => {
            }
          );
      }
    },
      err => console.error(err),
      () => {
      }
    );
    //get rmacomponent
  //  getComponentList(clientId,Rmaid)
   //foreach
    //
   // getComponentCompData()
    //getComponentCompData()


  }
  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    // this.subscription.unsubscribe();

  }
}
