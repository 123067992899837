
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable()
export class AdminService implements CanActivate {

  constructor(private router: Router, public loginSrc: LoginService) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {
    const token = this.loginSrc.getTokenData();
    const userData = this.loginSrc.getUserData();

    if (token !== undefined && userData != undefined && userData.UserLevel && (userData.UserLevel == 'admin' || userData.UserLevel == 'adminreadonly')) {      
        console.log(token);
        return true;      
    } else {
      this.router.navigate(['admin/login']);
      return false;
    }   
  }




}




