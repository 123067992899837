import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MasterComponent } from './main/master.component';
import { LoginComponent } from './login/login.component';
import { Guard } from './guard/guard.component';
import { RmaAddProduct } from './rmaAdd/rmaAddComponent.component';
import { RmaAddComponent } from './rmaAdd/rmaAddComponentComp';
import { RmaAddService } from './rmaAdd/rmaAdd.service';
declare var $: any;
import { SECURE_ROUTES } from './secure.routes';
import { BackofficermahistoryComponent } from './backofficermahistory/backofficermahistory.component';
import { BackofficeusermanagementComponent } from './backofficeusermanagement/backofficeusermanagement.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RmaNew } from './rmaAdd/rmaAdd.component';
import { RmahistoryclientComponent } from './rmahistoryclient/rmahistoryclient.component';
import { FileBrowse } from './fileBrowse/fileBrowse.component';
import { NewrmaComponent } from './newrma/newrma.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { slimDescription } from './pipes/slimDescription.pipe'
import { CookieService } from 'ngx-cookie-service'
import { NgSelectModule } from '@ng-select/ng-select';
// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RegisterComponent } from './register/register.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { AdminloginComponent } from './adminlogin/adminlogin.component';
import { CheckloginGuard } from './services/checklogin.guard';
import { IntercepterService } from './services/intercepter.service';
import { AdminService } from './services/admin.service';
import { UserService } from './services/user.service';
import { AdminregisterComponent } from './adminregister/adminregister.component';

@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    LoginComponent,
    AdminloginComponent,
    RmaAddProduct,
    BackofficermahistoryComponent,
    BackofficeusermanagementComponent,
    RmaNew,
    RmahistoryclientComponent,
    RmaAddComponent,
    FileBrowse,
    NewrmaComponent,
    slimDescription,
    RegisterComponent,
    AdminregisterComponent,
    ActivitylogComponent
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, canActivate : [CheckloginGuard] },
      { path: 'admin/login', component: AdminloginComponent, canActivate : [CheckloginGuard] },
      { path: 'register', component: RegisterComponent },
     

      { path: '', component: MasterComponent, data: { title: 'Secure Views' }, children: SECURE_ROUTES }
    ]),
    ReactiveFormsModule,
  ],
  providers: [Guard, RmaAddService, CookieService, AdminService, UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IntercepterService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
