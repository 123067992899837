
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { OnInit, OnDestroy, Component, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RmaAddService } from './rmaAdd.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;
import Utils from '../helper/helpers'
import { Rma, RmaDetails, ReduceComponentDetails } from "../model/dbContext";
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UsermanagementService } from '../backofficeusermanagement/usermanagement.service';
import { retry } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

declare var $: any;
@Component({
  templateUrl: './rmaAdd.component.html',
  encapsulation: ViewEncapsulation.None
})

export class RmaNew implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('smallModal', null) smModal: ModalDirective;
  @ViewChild('smalllModal', null) smlModal: ModalDirective;
  fileForm: FormGroup;
  infoUser: {} = {};
  notif: any = {};
  clientId: string;
  rmaId: string;
  isEdit: boolean;
  tarckingNumberList: string[] = [];
  rmaD: Rma;
  mode: string;
  userData: {} = {};
  rmaComponentsList: ReduceComponentDetails[] = [];
  rmaComponentsListC: ReduceComponentDetails[] = [];
  rmaComponentsListP: ReduceComponentDetails[] = [];
  constructor(private userService: UsermanagementService,
    private activatedRoute: ActivatedRoute,
    private addService: RmaAddService,
    protected router: Router,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    public loginSrc: LoginService
  ) {
    this.userData = this.loginSrc.getUserData();
    this.fileForm = this.formBuilder.group({
      excleFile: ['', Validators.required],
    });
  }
  ngAfterViewInit() {



  }
  ngOnInit() {
    console.log("here");
    //get param and set mode
    this.getParamandSetEdit();
    //fill controls and grid(edit mode)

    this.mode = ""
    // if (this.isEdit) {
    //   this.fillControls();
    //   this.mode = "Edit"
    // }
    // else {
    //   this.mode = "New"
    //   this.rmaD = new Rma();
    //   this.rmaD.ContactName = this.cookieService.get("Name");
    //   this.rmaD.ContactEmail = this.cookieService.get("Email");
    //   this.rmaD.ContactPhone = this.cookieService.get("Phone");
    //   this.rmaD.CompanyName = this.cookieService.get("CompanyName");
    //   this.rmaD.ContactReturnAddress = this.cookieService.get("Address");

    //   this.rmaD.TrackingNumber = "";
    //   this.rmaD.DeliveryMethod = "";
    //   console.log(this.rmaD)


    // }
    this.getUserInfo();



  }
  getParamandSetEdit() {
    // this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    // this.rmaId = this.activatedRoute.snapshot.paramMap.get('rmaId');
    // this.isEdit = this.rmaId == "-1" ? false : true;
    // console.log(this.rmaId);
    this.activatedRoute.params.subscribe(param => {
      console.log(param);
      this.clientId = param['clientId'];
      this.rmaId = param['rmaId'];
      this.isEdit = this.rmaId == "-1" ? false : true;
      if (this.isEdit) {
        this.fillControls();
        this.mode = "Edit"
      }
      else {
        this.mode = "New"
        this.rmaD = new Rma();
        console.log("this.userData", this.userData)
        

        this.rmaD.ContactName = this.cookieService.get("Name");
        this.rmaD.ContactEmail = this.cookieService.get("Email");
        this.rmaD.ContactPhone = this.cookieService.get("Phone");
        this.rmaD.CompanyName = this.cookieService.get("CompanyName");
        this.rmaD.ContactReturnAddress = this.cookieService.get("Address");

        this.rmaD.TrackingNumber = "";
        this.rmaD.DeliveryMethod = "";
        console.log(this.rmaD)


      }
    })
  }
  fillControls() {
    //get rma
    //pull data //pull files
    this.addService.getRmaData(this.clientId, this.rmaId).subscribe(el => {
      this.rmaD = el;

      console.log("this.userData", this.userData);
      console.log(!this.cookieService.get("Name"));
      if (!this.cookieService.get("Name")) {

        this.cookieService.set("Name", this.userData["Name"]);
        this.cookieService.set("Phone", this.userData["Phone"]);
        this.cookieService.set("Email", this.userData["Email"]);
        this.cookieService.set("CompanyName", this.userData["CompanyName"]);
        this.cookieService.set("ClientId", this.userData["Id"]);
        this.cookieService.set("Address", this.userData["Address"]);

      }

      this.rmaD.ContactName = this.cookieService.get("Name");
      this.rmaD.ContactEmail = this.cookieService.get("Email");
      this.rmaD.ContactPhone = this.cookieService.get("Phone");
      this.rmaD.CompanyName = this.cookieService.get("CompanyName");
      this.rmaD.ContactReturnAddress = this.cookieService.get("Address");
      console.log(this.rmaD);
    },
      err => console.error(err),
      () => {
      }
    );
    //get components list
    this.addService.getComponentList(this.clientId, this.rmaId).subscribe(el => {
      this.rmaComponentsList = el;
      this.rmaComponentsListC = this.rmaComponentsList.filter(x => x.isC == true);
      this.rmaComponentsListP = this.rmaComponentsList.filter(x => x.isC == false);
      console.log("this.rmaComponentsListC", this.rmaComponentsListC)
      console.log("this.rmaproductlistp", this.rmaComponentsListP);


    },
      err => console.error(err),
      () => {
      }
    );

  }
  addC($event: any) {
    //CHECK REQUIRE FILEDS
    $event.stopPropagation();
    $event.preventDefault();
    if ($("#txtName").val() == "" || $("#txtEmail").val() == "" || $("#txtTel").val() == "") {
      $.MessageBox("Please insert require fields");
      return;
    }
    //save or update to db, redirect to add component screen with rmaId
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = $(target).attr("id");
    this.saveToDb(idAttr);


  }
  getUserInfo() {

    this.userService.getUserInfo(this.clientId).subscribe(el => {
      if (el) {
        var a = el.Value;
        this.infoUser["Email"] = a.Email;
        this.infoUser["Phone"] = a.Phone;
        this.infoUser["Address"] = a.Address;
        this.infoUser["companyName"] = a.Company_Name_Tmp;
        this.infoUser["CustomerId"] = a.CustomerId;

        console.log(el.Value)
      }
    });
  }
  saveToDb(id) {

    this.rmaD = new Rma();
    this.rmaD.ContactName = $("#txtName").val();
    this.rmaD.ContactEmail = $("#txtEmail").val();
    this.rmaD.ContactPhone = $("#txtPhone").val();
    this.rmaD.CompanyName = $("#txtCompany").val();
    this.rmaD.ContactReturnAddress = $("#txtReturnAddress").val();
    this.rmaD.DeliveryMethod = $("#ddlDelivery").val();
    this.rmaD.TrackingNumber = this.tarckingNumberList.join(",");
    this.rmaD.IsOutOfWarrantyRma = $("#chkW").is(":checked");
    //set new cookie to new address, alse save it to db under users table
    this.cookieService.set("Address", $("#txtReturnAddress").val());
    this.cookieService.set("Phone", $("#txtPhone").val());
    this.addService.addOrUpdareRma(this.rmaD, this.clientId, this.rmaId).subscribe(el => {
      if (el)
        this.rmaId = el;
      console.log("this.rmaId", this.rmaId);

      if (id == "p") {//redirect to component screen
        this.router.navigate(['/rmaAdd/', this.clientId, "-1", this.rmaId], { relativeTo: this.activatedRoute });
      }
      else {//redirect to product screen
        this.router.navigate(['/rmaAddC/', this.clientId, "-1", this.rmaId], { relativeTo: this.activatedRoute });
      }
      console.log(el);
    },
      err => console.error(err),
      () => {
      }
    );
  }
  sta($event) {
    $event.stopPropagation();
    $event.preventDefault();
    if ($(".table_contn").length == 0) {
      $.MessageBox("No data, please add products / components");
      return;
    }
    $("#imgLoad").css("display", "inline");
    this.addService.updateSatus(this.clientId, this.rmaId).subscribe(el => {
      $.MessageBox("Approval request sent");
      $("#imgLoad").css("display", "none");

    },
      err => console.error(err),
      () => {
      }
    );
  }
  addP($event: any) {
    //CHECK REQUIRE FILEDS
    $event.stopPropagation();
    $event.preventDefault();
    if ($("#txtName").val() == "" || $("#txtEmail").val() == "" || $("#txtTel").val() == "" || $("#txtCompany").val() == "") {
      $.MessageBox("Please insert require fields");
    }
  }
  SendTracking() {
    if (this.rmaD.Status != "Approved") {
      $.MessageBox("Tracking can be added only after <b>Approvel</b>");
    }
    else if ($("#txtTracking").val() == "") {
      $.MessageBox("Tracking number can't be empty</b>");
    }
    else {

      this.tarckingNumberList.push($("#txtTracking").val());


      this.rmaD.DeliveryMethod = $("#ddlDelivery").val();

      if (this.tarckingNumberList.length > 1)
        this.rmaD.TrackingNumber = this.tarckingNumberList.join(",");
      else
        this.rmaD.TrackingNumber = $("#txtTracking").val();

      this.addService.updateTrackingNumber(this.rmaD, this.clientId, this.rmaId).subscribe(el => {
        console.log(el)
        $.MessageBox("Tracking Was Updated Successfully");
      },
        err => console.error(err),
        () => {
        }
      );
    }
  }
  openModel() {
    console.log(this.rmaD);

    $.MessageBox({
      input: true,
      message: "What's tracking number?"
    }).done(function (data) {
      if ($.trim(data)) {
        $.MessageBox("tacking <b>" + data + "</b> was added!");
        this.tarckingNumberList.push(data);
        console.log(this.tarckingNumberList);
      } else {
        $.MessageBox("Please insert values");
      }
    }.bind(this))
  }

  open() {
    if ($("#txtName").val() == "" || $("#txtEmail").val() == "" || $("#txtTel").val() == "") {
      $.MessageBox("Please insert require fields");
      return;
    }
    this.smlModal.show()

  }

  uploadFile() {
    $("body").LoadingOverlay("show");

    console.log(this.isEdit);
    this.rmaD = new Rma();
    this.rmaD.ContactName = $("#txtName").val();
    this.rmaD.ContactEmail = $("#txtEmail").val();
    this.rmaD.ContactPhone = $("#txtPhone").val();
    this.rmaD.CompanyName = $("#txtCompany").val();
    this.rmaD.ContactReturnAddress = $("#txtReturnAddress").val();
    this.rmaD.DeliveryMethod = $("#ddlDelivery").val();
    this.rmaD.TrackingNumber = this.tarckingNumberList.join(",");
    this.rmaD.IsOutOfWarrantyRma = $("#chkW").is(":checked");
    this.rmaD.CustomerId = this.infoUser["CustomerId"];
    this.rmaD.UserId = parseInt(this.clientId);
    //set new cookie to new address, alse save it to db under users table
    this.cookieService.set("Address", $("#txtReturnAddress").val());
    this.cookieService.set("Phone", $("#txtPhone").val()); console.log(this.fileForm.controls['excleFile'].value)
    const formData = new FormData();
    formData.append('file', this.fileForm.controls['excleFile'].value);
    console.log(formData)
    formData.append('rmaDetail1', JSON.stringify(this.rmaD));
    formData.append('rmaId', JSON.stringify(this.rmaId));
    formData.append('isEdit', JSON.stringify(this.isEdit));

    this.addService.upload(formData).subscribe((res: any) => {
      console.log(res)
      this.smlModal.hide();
      $("body").LoadingOverlay("hide");
      if (res.Value == 'wrong extension') {
        $.MessageBox("Please choose excle file");
      }
        else if (res.Value.indexOf('Wrong Serial') > -1) {
        $.MessageBox("One or more serials are wrong , opration aborted, " + res.Value);
        return;
      } else {
        this.router.navigate(['/rmaAddNew/', this.clientId, res.Value], { relativeTo: this.activatedRoute });
        this.ngOnInit();
      }
    }, err => {
      console.log(err)
      this.smlModal.hide();
      $("body").LoadingOverlay("hide");
      $.MessageBox("Something went wrong please try again");

    })
    // this.uploadResponse = res,
    //   (err) => this.error = err
    // );


  }




  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileForm.controls['excleFile'].setValue(file);
    }
  }






  updateC(data, id: string, isC: boolean) {
    console.log(data, id, isC);
    if (data.Dis_Approve == true) {
      this.notif.heading = "information";
      this.notif.message = data.Dis_Approve_Reason;
      this.smModal.show();


    } else {
      if (!isC)
        this.router.navigate(['/rmaAdd/', this.clientId, id, this.rmaId]);
      else
        this.router.navigate(['/rmaAddC/', this.clientId, id, this.rmaId]);
    }

  }
  deleteC(id: string, isC: boolean) {

    this.addService.deleteC(id, isC.toString()).subscribe(el => {
      // this.rmaD = el;
      console.log(this.rmaD);
      this.rmaComponentsListC = this.rmaComponentsListC.filter(x => x.Id !== parseInt(id));
      this.rmaComponentsListP = this.rmaComponentsListP.filter(x => x.Id !== parseInt(id));
    },
      err => console.error(err),
      () => {
      }
    );
  }
  redirect($event: any, redirect) {
    //CHECK REQUIRE FILEDS
    $event.stopPropagation();
    $event.preventDefault();
    if (redirect) {
      this.router.navigate(['/historyclient/', this.clientId], { relativeTo: this.activatedRoute });
    }

  }

  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    // this.subscription.unsubscribe();

  }
}
